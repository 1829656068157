.file-input {
  padding: 0;
  margin: 0;
  
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: rem(15);

  cursor: pointer;
  user-select: none;

  @include mediaBigDesktop {
    gap: big(15);
  }

  @include hover {
    & .file-input {
      &__icon {
        fill: var(--accent-color);
      }
    }
  }

  &__button {
    padding: rem(11);
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark);
    border-radius: var(--radius-common);

    @include mediaBigDesktop {
      padding: big(11);

      border-width: big(1);
    }
  }

  &__icon {
    width: rem(20);
    height: rem(20);

    fill: var(--elements-dark);

    transition: fill var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(20);
      height: big(20);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }

  &__name {
    font-weight: 600;
  }

  &__type {
    text-transform: uppercase;
    color: var(--text-dark-secondary);
  }

  &__input {
    &:focus {
      &~.file-input__button {
        &>.file-input__icon {
          fill: var(--accent-color);
        }
      }
    }

    &:disabled {
      &~.file-input {
        &__button {
          border-color: var(--stroke-dark);

          &>.file-input__icon {
            fill: var(--text-disable);
          }
        }

        &__wrapper {
          &>.file-input {

            &__name,
            &__type {
              color: var(--text-disable);
            }
          }
        }
      }
    }
  }
}

.file-input-images {
  display: flex;
  gap: rem(20);
    
  overflow: auto;
  
  @include scrollbarHorizontal();

  @include mediaBigDesktop {
    gap: big(20);
  }
    
  & .zoom-image {
    flex-shrink: 0;
  }
}
