.tag {  
  margin: 0;
  padding: rem(2) rem(8);

  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--text-light-primary);
  font-size: rem(14);
  font-weight: 400;
  line-height: 1.5;

  background: none;
  background-color: var(--background-color);
  border: none;

  border-radius: var(--radius-secondary);
  
  text-decoration: none;
  user-select: none;
  transition: background-color var(--animation-timing) var(--cubic-bezier);

  @include mediaBigDesktop {
    padding: big(2) big(8);

    font-size: big(14);
  }

  @include mediaLaptop {
    padding: rem(2) rem(6);

    font-size: rem(12);
  }
  
  @include hover {
    background-color: var(--background-color-hover);
  }
  
  &_state-register {
    --background-color: #fff;
    --background-color-hover: #fff;
    --text-light-primary: var(--accent-color-dark);
    
    font-weight: 600;
    
    border: 1px solid var(--accent-color-dark);
    
    pointer-events: none;
  }
}
