.logo {
  width: fit-content;
  height: fit-content;
  display: flex;

  background-color: transparent;

  transition: opacity var(--animation-timing) var(--cubic-bezier);

  cursor: pointer;

  @include hover {
    opacity: .9;
  }

  &__image {
    width: 100%;
    height: rem(50);

    object-fit: contain;
    object-position: center;

    @include mediaBigDesktop {
      height: big(50);
    }

    @include mediaLaptop {
      height: rem(44);
    }

    @include mediaMobile {
      width: auto;
      height: rem(34);
    }
  }
}
