@import '../../mixins';

.modal-action {
  position: relative;
  margin-top: rem(40);
  padding: rem(40) 0 0;

  display: flex;
  flex-direction: column;
  gap: rem(25);

  @include mediaBigDesktop {
    margin-top: big(40);
    padding: big(40) 0 0;

    gap: big(25);
  }

  @include mediaLaptop {
    margin-top: rem(30);
    padding-top: rem(30);
  }

  @include mediaMobile {
    margin-top: rem(25);
    padding-top: rem(25);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: rem(-40);

    width: calc(100% + (#{rem(40)} * 2));
    height: 1px;

    background-color: var(--stroke-dark);

    @include mediaBigDesktop {
      left: big(-40);

      width: calc(100% + (#{big(40)} * 2));
    }

    @include mediaLaptop {
      left: rem(-30);

      width: calc(100% + (#{rem(30)} * 2));
    }

    @include mediaMobile {
      left: rem(-10);

      width: calc(100% + (#{rem(10)} * 2));
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: rem(25);

    @include mediaBigDesktop {
      gap: big(25);
    }
  }

  &__tab {
    position: relative;
    left: rem(-40);
    padding: 0 rem(40);

    width: calc(100% + (#{rem(40)} * 2));
    display: flex;
    flex-direction: row;
    gap: rem(10);

    overflow-x: auto;
    overflow-y: hidden;

    @include mediaBigDesktop {
      left: big(-40);
      padding: 0 big(40);

      width: calc(100% + (#{big(40)} * 2));
      gap: big(10);
    }

    @include mediaLaptop {
      left: rem(-30);
      padding: 0 rem(30);
      width: calc(100% + (#{rem(30)} * 2));
    }

    @include mediaMobile {
      left: rem(-10);
      padding: 0 rem(10);
      width: calc(100% + (#{rem(10)} * 2));
    }

    @include scrollbarHorizontal();

    & .tab {
      width: rem(127.5);
      flex-grow: 1;

      @include mediaBigDesktop {
        width: big(127.5);
      }

      &__content {
        width: 100%;
      }
    }
  }

  &__submit {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    & .checkbox {
      & .text {
        color: var(--text-dark-secondary);
      }
    }

    & .button {
      width: 100%;
    }
  }

  &__input {
    & .text-input {
      display: none;

      &_active {
        display: flex;
      }
    }
  }
}
