blockquote {
  margin: 0;
  padding: rem(30);

  display: flex;
  flex-direction: column;
  gap: rem(20);

  color: var(--text-dark-secondary);
  font-size: rem(18);
  font-style: italic;
  font-weight: 400;
  line-height: 1.5;

  background-color: var(--bg-light);
  border-radius: var(--radius-common);

  @include mediaBigDesktop {
    padding: big(30);

    gap: big(20);

    font-size: big(18);
  }

  @include mediaLaptop {
    gap: rem(15);
    
    font-size: rem(16);
  }

  @include mediaMobile {
    font-size: rem(14);
  }

  &::before {
    content: "";

    width: rem(30);
    height: rem(24);
    display: flex;

    background-color: var(--accent-color);

    mask-image: url("data:image/svg+xml,%3Csvg width='30' height='24' viewBox='0 0 30 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.0909 0C1.72727 3.04615 0 8.76923 0 14.1231C0 20.7692 3.81818 23.9077 7.54545 23.9077C8.30612 23.9547 9.06809 23.8403 9.78282 23.5718C10.4975 23.3032 11.1493 22.8865 11.6967 22.3481C12.244 21.8097 12.6749 21.1614 12.9619 20.4446C13.2489 19.7278 13.3857 18.9581 13.3636 18.1846C13.3636 14.5846 10.8182 12.8308 8.09091 12.4615C5.72727 12.1846 4.18182 11.0769 4.18182 8.30769C4.18182 6.55385 4.63636 3.6 11.5455 1.01538L11.0909 0ZM27.1818 0C17.8182 3.04615 16 8.76923 16 14.1231C16 20.7692 19.9091 23.9077 23.6364 23.9077C24.397 23.9547 25.159 23.8403 25.8737 23.5718C26.5884 23.3032 27.2402 22.8865 27.7876 22.3481C28.335 21.8097 28.7659 21.1614 29.0529 20.4446C29.3399 19.7278 29.4767 18.9581 29.4545 18.1846C29.4545 14.5846 26.9091 12.8308 24.1818 12.4615C21.8182 12.1846 20.1818 11.0769 20.1818 8.30769C20.1818 6.55385 20.7273 3.6 27.6364 1.01538L27.1818 0Z' fill='black'/%3E%3C/svg%3E%0A");
    mask-size: contain;
    mask-repeat: no-repeat;
  }

}
