@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/Open_Sans/Regular/OpenSans-Regular.woff2") format("woff2"), url("../fonts/Open_Sans/Regular/OpenSans-Regular.woff") format("woff"), url("../fonts/Open_Sans/Regular/OpenSans-Regular.eot") format("eot"), url("../fonts/Open_Sans/Regular/OpenSans-Regular.ttf") format("ttf"), url("../fonts/Open_Sans/Regular/OpenSans-Regular.svg") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/Open_Sans/SemiBold/OpenSans-SemiBold.woff2") format("woff2"), url("../fonts/Open_Sans/SemiBold/OpenSans-SemiBold.woff") format("woff"), url("../fonts/Open_Sans/SemiBold/OpenSans-SemiBold.eot") format("eot"), url("../fonts/Open_Sans/SemiBold/OpenSans-SemiBold.ttf") format("ttf"), url("../fonts/Open_Sans/SemiBold/OpenSans-SemiBold.svg") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/Open_Sans/Italic/OpenSans-Italic.woff2") format("woff2"), url("../fonts/Open_Sans/Italic/OpenSans-Italic.woff") format("woff"), url("../fonts/Open_Sans/Italic/OpenSans-Italic.eot") format("eot"), url("../fonts/Open_Sans/Italic/OpenSans-Italic.ttf") format("ttf"), url("../fonts/Open_Sans/Italic/OpenSans-Italic.svg") format("svg");
  font-weight: 400;
  font-style: italic;
  font-display: swap; }

:root {
  --font-primary: "Open Sans", sans-serif;
  --content-width: 141.25rem;
  --container-offset: 9.375rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --article-width: 67.125rem;
  --shadow-large: 0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 10px 24px 0px rgba(0, 0, 0, 0.04), 0px 15px 32px 0px rgba(0, 0, 0, 0.04);
  --shadow-small: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  --cubic-bezier: cubic-bezier(.25, .1, .25, 1);
  --animation-timing: 0.45s;
  --animation-timing-medium: 0.7s;
  --animation-timing-long: 1s;
  --section-padding: 4.6875rem;
  --section-padding-common: 3.125rem;
  --section-gap: 2.5rem;
  --section-gap-common: 3.125rem;
  --section-wrapper-gap: 2.1875rem;
  --section-text-color-primary: var(--text-dark-primary);
  --section-text-color-secondary: var(--text-dark-secondary);
  --section-background-color: var(--bg-light);
  --section-hidden-color: var(--bg-light);
  --radius-common: 0.25rem;
  --radius-secondary: 0.1875rem;
  --grid-column-gap: 1.25rem;
  --grid-row-gap: 1.25rem; }
  @media screen and (min-width: 160.0625rem) {
    :root {
      --content-width: 88.28125vw;
      --container-offset: 5.85938vw; } }
  @media screen and (max-width: 87.5rem) {
    :root {
      --container-offset: 1.25rem; } }
  @media screen and (max-width: 39.375rem) {
    :root {
      --container-offset: 0.9375rem; } }
  @media screen and (min-width: 160.0625rem) {
    :root {
      --article-width: 41.95312vw; } }
  @media screen and (max-width: 109.375rem) {
    :root {
      --article-width: 51.25rem; } }
  @media screen and (min-width: 131.25rem) {
    :root {
      --section-padding: 6.25rem;
      --section-padding-common: 3.125rem;
      --section-gap: 2.5rem;
      --section-gap-common: 3.125rem;
      --section-wrapper-gap: 2.1875rem; } }
  @media screen and (min-width: 160.0625rem) {
    :root {
      --section-padding: 3.90625vw;
      --section-padding-common: 1.95312vw;
      --section-gap: 1.5625vw;
      --section-gap-common: 1.95312vw;
      --section-wrapper-gap: 1.36719vw; } }
  @media screen and (max-width: 109.375rem) {
    :root {
      --section-padding: 3.75rem;
      --section-padding-common: 2.5rem;
      --section-gap: 1.875rem;
      --section-gap-common: 2.5rem;
      --section-wrapper-gap: 1.875rem; } }
  @media screen and (max-width: 87.5rem) {
    :root {
      --section-padding-common: 1.875rem;
      --section-gap-common: 1.875rem; } }
  @media screen and (max-width: 39.375rem) {
    :root {
      --section-padding: 2.5rem;
      --section-padding-common: 1.5625rem;
      --section-gap: 1.25rem;
      --section-gap-common: 1.5625rem;
      --section-wrapper-gap: 1.5625rem; } }
  @media screen and (min-width: 160.0625rem) {
    :root {
      --radius-common: 0.15625vw;
      --radius-secondary: 0.11719vw; } }
  @media screen and (min-width: 160.0625rem) {
    :root {
      --grid-column-gap: 0.78125vw;
      --grid-row-gap: 0.78125vw; } }
  @media screen and (max-width: 39.375rem) {
    :root {
      --grid-column-gap: 0.9375rem;
      --grid-row-gap: 0.9375rem; } }

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; }

.page {
  height: 100%;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  -webkit-text-size-adjust: 100%; }

.main {
  height: 100%;
  display: flex;
  flex-direction: column; }

::selection {
  background: var(--accent-color);
  color: var(--text-light-primary); }

::-moz-selection {
  background: var(--accent-color);
  color: var(--text-light-primary); }

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 1rem;
  background-color: var(--bg-white); }
  .page__body::-webkit-scrollbar {
    width: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .page__body::-webkit-scrollbar {
        width: 0.39062vw; } }
  .page__body::-webkit-scrollbar-track {
    background: var(--bg-white); }
  .page__body::-webkit-scrollbar-thumb {
    background-color: var(--elements-gray-secondary);
    border-radius: 62.4375rem;
    border: 3px solid var(--bg-white); }
    @media screen and (min-width: 160.0625rem) {
      .page__body::-webkit-scrollbar-thumb {
        border-radius: 39.02344vw;
        border-width: 0.11719vw; } }

.disable-scroll {
  width: 100%;
  height: 100vh;
  overflow: hidden; }

.wrapper {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  flex-direction: column; }
  .wrapper__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }

.footer {
  margin-top: auto; }

.input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: var(--bg-white); }
  .input::-webkit-search-decoration, .input::-webkit-search-cancel-button, .input::-webkit-search-results-button, .input::-webkit-search-results-decoration {
    display: none; }
  .input::-webkit-outer-spin-button, .input::-webkit-inner-spin-button {
    -webkit-appearance: none; }
  .input[type=number] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield; }

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0); }

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width); }

.reset-styles {
  padding: 0;
  margin: 0; }

.disabled {
  opacity: 0.5;
  pointer-events: none;
  transform: opacity var(--animation-timing) var(--cubic-bezier); }

.elite-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  cursor: pointer;
  transition: opacity var(--animation-timing-medium) var(--cubic-bezier) var(--animation-timing), visibility var(--animation-timing-medium) var(--cubic-bezier) var(--animation-timing); }
  .elite-backdrop_show {
    opacity: 0.6;
    visibility: visible;
    pointer-events: all; }

.text {
  padding: 0;
  margin: 0; }
  .text_body-large {
    font-size: 1.125rem;
    line-height: 1.5; }
    @media screen and (min-width: 160.0625rem) {
      .text_body-large {
        font-size: 0.70312vw; } }
    @media screen and (max-width: 109.375rem) {
      .text_body-large {
        font-size: 1rem; } }
    @media screen and (max-width: 39.375rem) {
      .text_body-large {
        font-size: 0.875rem; } }
  .text_body-medium {
    font-size: 1rem;
    line-height: 1.5; }
    @media screen and (min-width: 160.0625rem) {
      .text_body-medium {
        font-size: 0.625vw; } }
    @media screen and (max-width: 109.375rem) {
      .text_body-medium {
        font-size: 0.875rem; } }
  .text_body-small {
    font-size: 0.875rem;
    line-height: 1.5; }
    @media screen and (min-width: 160.0625rem) {
      .text_body-small {
        font-size: 0.54688vw; } }
    @media screen and (max-width: 109.375rem) {
      .text_body-small {
        font-size: 0.75rem; } }
  .text_body-xs {
    font-size: 0.75rem;
    line-height: 1.5; }
    @media screen and (min-width: 160.0625rem) {
      .text_body-xs {
        font-size: 0.46875vw; } }
  .text_body_semibold {
    font-weight: 600; }
  .text_caption {
    font-size: 0.875rem;
    line-height: 1.5;
    letter-spacing: 0.7px;
    text-transform: uppercase; }
    @media screen and (min-width: 160.0625rem) {
      .text_caption {
        font-size: 0.54688vw;
        letter-spacing: 0.02734vw; } }
    @media screen and (max-width: 109.375rem) {
      .text_caption {
        font-size: 0.75rem;
        letter-spacing: 0.0375rem; } }
  .text_semibold {
    font-weight: 600; }
  .text_regular {
    font-weight: 400; }
  .text_italic {
    font-style: italic;
    font-weight: 400; }

.h1,
.h2,
.h3 {
  padding: 0;
  margin: 0; }

.h1,
.article h1 {
  font-size: 2.375rem;
  font-weight: 600;
  line-height: 1.1; }
  @media screen and (min-width: 160.0625rem) {
    .h1,
    .article h1 {
      font-size: 1.48438vw; } }
  @media screen and (max-width: 109.375rem) {
    .h1,
    .article h1 {
      font-size: 2.125rem; } }
  @media screen and (max-width: 87.5rem) {
    .h1,
    .article h1 {
      font-size: 1.625rem; } }
  @media screen and (max-width: 39.375rem) {
    .h1,
    .article h1 {
      font-size: 1.375rem; } }

.h2,
.article h2 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2; }
  @media screen and (min-width: 160.0625rem) {
    .h2,
    .article h2 {
      font-size: 0.9375vw; } }
  @media screen and (max-width: 109.375rem) {
    .h2,
    .article h2 {
      font-size: 1.375rem;
      line-height: 1.1; } }
  @media screen and (max-width: 87.5rem) {
    .h2,
    .article h2 {
      font-size: 1.25rem; } }
  @media screen and (max-width: 39.375rem) {
    .h2,
    .article h2 {
      font-size: 1.125rem; } }

.h3,
.article h3,
.article h4,
.article h5,
.article h6 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4; }
  @media screen and (min-width: 160.0625rem) {
    .h3,
    .article h3,
    .article h4,
    .article h5,
    .article h6 {
      font-size: 0.78125vw; } }
  @media screen and (max-width: 109.375rem) {
    .h3,
    .article h3,
    .article h4,
    .article h5,
    .article h6 {
      font-size: 1.125rem; } }
  @media screen and (max-width: 39.375rem) {
    .h3,
    .article h3,
    .article h4,
    .article h5,
    .article h6 {
      font-size: 1rem; } }

.button {
  position: relative;
  padding: 0;
  margin: 0;
  width: fit-content;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  background: transparent;
  border: none;
  border-radius: var(--radius-common);
  cursor: pointer;
  user-select: none;
  overflow: hidden; }
  @media screen and (min-width: 160.0625rem) {
    .button {
      font-size: 0.625vw; } }
  @media screen and (max-width: 109.375rem) {
    .button {
      font-size: 0.875rem; } }
  .button:disabled, .button.disabled {
    color: var(--text-disable);
    background-color: var(--bg-disable);
    pointer-events: none; }
    .button:disabled .button__icon, .button.disabled .button__icon {
      fill: var(--text-disable); }
  .button_primary {
    padding: 0.625rem 1.25rem;
    min-height: 2.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-light-primary);
    background-color: var(--accent-color); }
    @media screen and (min-width: 160.0625rem) {
      .button_primary {
        padding: 0.39062vw 0.78125vw;
        min-height: 1.71875vw; } }
    @media (any-hover: hover) {
      .button_primary:hover::before {
        left: 0;
        right: unset;
        width: 100%; } }
    .button_primary::before {
      content: "";
      position: absolute;
      z-index: 0;
      right: 0;
      top: 0;
      width: 0%;
      height: 100%;
      background-color: var(--accent-color-dark);
      border-radius: var(--radius-common);
      transition: width var(--animation-timing-medium) var(--cubic-bezier); }
  .button_secondary {
    padding: 0.5rem 1.125rem;
    min-height: 2.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--accent-color);
    background: transparent;
    border: solid 1px var(--accent-color);
    transition: background-color var(--animation-timing) var(--cubic-bezier), color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .button_secondary {
        padding: 0.3125vw 0.70312vw;
        min-height: 1.71875vw;
        border-width: 0.03906vw; } }
    @media (any-hover: hover) {
      .button_secondary:hover {
        color: var(--text-light-primary); }
        .button_secondary:hover::before {
          left: 0;
          right: unset;
          width: 100%; }
        .button_secondary:hover .button__icon {
          fill: var(--bg-white); } }
    .button_secondary .button__icon {
      fill: var(--accent-color); }
    .button_secondary::before {
      content: "";
      position: absolute;
      z-index: 0;
      right: 0;
      top: 0;
      width: 0%;
      height: 100%;
      background-color: var(--accent-color);
      transition: width var(--animation-timing-medium) var(--cubic-bezier); }
    .button_secondary:disabled, .button_secondary.disabled {
      background-color: transparent;
      border: solid 1px var(--stroke-dark); }
      @media screen and (min-width: 160.0625rem) {
        .button_secondary:disabled, .button_secondary.disabled {
          border-width: 0.03906vw; } }
  .button_tertiary {
    position: relative;
    display: flex;
    gap: 0.3125rem;
    color: var(--accent-color);
    transition: color var(--animation-timing) var(--cubic-bezier);
    overflow: hidden; }
    @media screen and (min-width: 160.0625rem) {
      .button_tertiary {
        gap: 0.19531vw; } }
    .button_tertiary .button__content {
      gap: 0.3125rem;
      pointer-events: none; }
      @media screen and (min-width: 160.0625rem) {
        .button_tertiary .button__content {
          gap: 0.19531vw; } }
    .button_tertiary .button__icon {
      position: relative;
      fill: var(--accent-color); }
    .button_tertiary.button_icon-right .button__icon {
      transform: scale(-1, 1); }
    @media (any-hover: hover) {
      .button_tertiary:hover {
        color: var(--accent-color-dark); }
        .button_tertiary:hover .button__icon {
          fill: var(--accent-color-dark); } }
    .button_tertiary:disabled, .button_tertiary.disabled {
      color: var(--text-disable);
      background-color: transparent; }
    .button_tertiary::before {
      content: "";
      position: absolute;
      top: -0.9375rem;
      left: -0.9375rem;
      width: calc(100% + 0.9375rem * 2);
      height: calc(100% + 0.9375rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .button_tertiary::before {
          top: -0.58594vw;
          left: -0.58594vw;
          width: calc(100% + 0.58594vw * 2);
          height: calc(100% + 0.58594vw * 2); } }
  .button_more {
    margin-right: auto;
    margin-left: auto; }
  .button_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--elements-dark);
    border: solid 1px var(--stroke-dark);
    background-color: var(--bg-white);
    transition: color var(--animation-timing) var(--cubic-bezier), border-color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .button_icon {
        border-width: 0.03906vw; } }
    @media (any-hover: hover) {
      .button_icon:hover {
        color: var(--accent-color); }
        .button_icon:hover .button__icon {
          fill: var(--accent-color); }
        .button_icon:hover .button__content {
          color: var(--accent-color); } }
    .button_icon.animation {
      --before-top: 0;
      --before-right: 0;
      --before-bottom: 0;
      --before-left: 0;
      --before-size: 0;
      --before-scale: 0; }
      @media (any-hover: hover) {
        .button_icon.animation:hover {
          color: var(--text-light-primary);
          border-color: var(--accent-color); }
          .button_icon.animation:hover .button__icon {
            fill: var(--elements-white); }
          .button_icon.animation:hover .button__content {
            color: var(--text-light-primary); } }
      .button_icon.animation::after {
        content: "";
        position: absolute;
        z-index: 0;
        top: var(--before-top);
        left: var(--before-left);
        width: var(--before-size);
        height: var(--before-size);
        background-color: var(--accent-color);
        border-radius: 100rem;
        transform: translate(-50%, -50%); }
        @media screen and (min-width: 160.0625rem) {
          .button_icon.animation::after {
            border-radius: 62.5vw; } }
    @media (any-hover: hover) {
      .button_icon.msfavorites:hover .button__icon {
        fill: transparent;
        stroke: var(--elements-white); } }
  @media screen and (any-hover: hover) and (max-width: 39.375rem) {
    .button_icon.msfavorites:hover .button__icon {
      stroke: var(--accent-color); } }
    .button_icon.msfavorites .button__icon {
      fill: transparent;
      stroke: var(--elements-dark);
      stroke-width: 1.5;
      transition: fill var(--animation-timing) var(--cubic-bezier), stroke var(--animation-timing) var(--cubic-bezier); }
    .button_icon.msfavorites.voted .button__icon {
      fill: var(--accent-color);
      stroke: var(--accent-color); }
    @media (any-hover: hover) {
      .button_icon.msfavorites.voted:hover .button__icon {
        fill: var(--elements-white);
        stroke: var(--elements-white); } }
    .button_icon:disabled, .button_icon.disabled {
      fill: var(--text-disable);
      background-color: transparent; }
    .button_icon-right .button__content {
      flex-direction: row; }
    .button_icon .button__icon {
      position: relative;
      z-index: 1;
      fill: var(--elements-dark); }
    .button_icon .button__content {
      pointer-events: none; }
    .button_icon.large {
      padding: 0.6875rem; }
      @media screen and (min-width: 160.0625rem) {
        .button_icon.large {
          padding: 0.42969vw; } }
    .button_icon.medium {
      padding: 0.375rem; }
      @media screen and (min-width: 160.0625rem) {
        .button_icon.medium {
          padding: 0.23438vw; } }
    .button_icon.small {
      padding: 0.1875rem; }
      @media screen and (min-width: 160.0625rem) {
        .button_icon.small {
          padding: 0.11719vw; } }
      .button_icon.small .button__icon {
        width: 1rem;
        height: 1rem; }
        @media screen and (min-width: 160.0625rem) {
          .button_icon.small .button__icon {
            width: 0.625vw;
            height: 0.625vw; } }
  .button_not-wrap {
    white-space: nowrap; }
  .button__icon {
    width: 1.25rem;
    height: 1.25rem;
    order: 2;
    fill: var(--bg-white);
    transition: fill var(--animation-timing) var(--cubic-bezier);
    pointer-events: none; }
    @media screen and (min-width: 160.0625rem) {
      .button__icon {
        width: 0.78125vw;
        height: 0.78125vw; } }
  .button__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 0.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .button__content {
        gap: 0.3125vw; } }
  .button_theme-whatsapp {
    background-color: #29A71A; }
    @media (any-hover: hover) {
      .button_theme-whatsapp:hover .button__icon {
        fill: var(--bg-white); } }
    .button_theme-whatsapp .button__icon {
      fill: var(--bg-white); }
  .button_theme-telegram {
    background-color: #29A9EB; }
    @media (any-hover: hover) {
      .button_theme-telegram:hover .button__icon {
        fill: var(--bg-white); } }
    .button_theme-telegram .button__icon {
      fill: var(--bg-white); }

.whatsapp-button {
  z-index: 10;
  position: fixed;
  right: 1.875rem;
  bottom: 0.625rem;
  padding: 0.625rem 0.9375rem;
  background-color: #28a745;
  text-decoration: none;
  z-index: 1000;
  color: #fff;
  box-shadow: 0.25rem 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s bottom; }
  @media screen and (min-width: 160.0625rem) {
    .whatsapp-button {
      right: 1.17188vw;
      bottom: 0.39062vw;
      padding: 0.39062vw 0.58594vw;
      box-shadow: 0.15625vw 0.15625vw 0.3125vw 0 rgba(0, 0, 0, 0.2); } }
  @media screen and (max-width: 39.375rem) {
    .whatsapp-button {
      right: 0.625rem;
      font-size: 0.75rem; } }
  .whatsapp-button:hover {
    color: #fff;
    text-decoration: none; }
  .whatsapp-button__icon {
    width: 2.1875rem;
    height: 2.1875rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.625rem;
    fill: var(--bg-white); }
    @media screen and (min-width: 160.0625rem) {
      .whatsapp-button__icon {
        width: 1.36719vw;
        height: 1.36719vw;
        margin-right: 0.39062vw; } }
    @media screen and (max-width: 39.375rem) {
      .whatsapp-button__icon {
        width: 1.5625rem;
        height: 1.5625rem;
        margin-right: 0; } }
  @media screen and (max-width: 39.375rem) {
    .whatsapp-button span {
      display: none; } }
  .whatsapp-button_up {
    bottom: 5rem; }
    @media screen and (min-width: 160.0625rem) {
      .whatsapp-button_up {
        bottom: 3.125vw; } }

.number-input {
  padding: 0.5625rem 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 0.625rem;
  border: solid 1px var(--stroke-dark);
  border-radius: var(--radius-common); }
  @media screen and (min-width: 160.0625rem) {
    .number-input {
      padding: 0.35156vw 0.54688vw;
      gap: 0.39062vw; } }
  .number-input_medium {
    padding: 0.34375rem 0.5625rem;
    gap: 0.3125rem; }
    @media screen and (min-width: 160.0625rem) {
      .number-input_medium {
        padding: 0.21484vw 0.35156vw;
        gap: 0.19531vw; } }
    .number-input_medium .number-input__button::before {
      content: "";
      position: absolute;
      top: -0.3125rem;
      left: -0.3125rem;
      width: calc(100% + 0.3125rem * 2);
      height: calc(100% + 0.3125rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .number-input_medium .number-input__button::before {
          top: -0.19531vw;
          left: -0.19531vw;
          width: calc(100% + 0.19531vw * 2);
          height: calc(100% + 0.19531vw * 2); } }
    .number-input_medium .number-input__input {
      height: 1.3125rem; }
      @media screen and (min-width: 160.0625rem) {
        .number-input_medium .number-input__input {
          height: 0.82031vw; } }
  .number-input__button {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    overflow: visible; }
    .number-input__button::before {
      content: "";
      position: absolute;
      top: -0.625rem;
      left: -0.625rem;
      width: calc(100% + 0.625rem * 2);
      height: calc(100% + 0.625rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .number-input__button::before {
          top: -0.39062vw;
          left: -0.39062vw;
          width: calc(100% + 0.39062vw * 2);
          height: calc(100% + 0.39062vw * 2); } }
    @media (any-hover: hover) {
      .number-input__button:hover .number-input__icon {
        fill: var(--accent-color); } }
  .number-input__icon {
    width: 1.25rem;
    height: 1.25rem;
    fill: var(--elements-dark);
    transition: fill var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .number-input__icon {
        width: 0.78125vw;
        height: 0.78125vw; } }
  .number-input__input {
    padding: 0;
    margin: 0;
    width: 1.5625rem;
    height: 1.5rem;
    text-align: center;
    font-weight: 600;
    outline: none;
    transition: color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .number-input__input {
        width: 0.97656vw;
        height: 0.9375vw; } }
  .number-input.disabled {
    opacity: 1; }
    .number-input.disabled .number-input__input {
      color: var(--text-disable); }
    .number-input.disabled .number-input__icon {
      fill: var(--text-disable); }

.file-input {
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0.9375rem;
  cursor: pointer;
  user-select: none; }
  @media screen and (min-width: 160.0625rem) {
    .file-input {
      gap: 0.58594vw; } }
  @media (any-hover: hover) {
    .file-input:hover .file-input__icon {
      fill: var(--accent-color); } }
  .file-input__button {
    padding: 0.6875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark);
    border-radius: var(--radius-common); }
    @media screen and (min-width: 160.0625rem) {
      .file-input__button {
        padding: 0.42969vw;
        border-width: 0.03906vw; } }
  .file-input__icon {
    width: 1.25rem;
    height: 1.25rem;
    fill: var(--elements-dark);
    transition: fill var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .file-input__icon {
        width: 0.78125vw;
        height: 0.78125vw; } }
  .file-input__wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start; }
  .file-input__name {
    font-weight: 600; }
  .file-input__type {
    text-transform: uppercase;
    color: var(--text-dark-secondary); }
  .file-input__input:focus ~ .file-input__button > .file-input__icon {
    fill: var(--accent-color); }
  .file-input__input:disabled ~ .file-input__button {
    border-color: var(--stroke-dark); }
    .file-input__input:disabled ~ .file-input__button > .file-input__icon {
      fill: var(--text-disable); }
  .file-input__input:disabled ~ .file-input__wrapper > .file-input__name, .file-input__input:disabled ~ .file-input__wrapper > .file-input__type {
    color: var(--text-disable); }

.file-input-images {
  display: flex;
  gap: 1.25rem;
  overflow: auto; }
  .file-input-images::-webkit-scrollbar {
    height: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .file-input-images::-webkit-scrollbar {
        height: 0.39062vw; } }
  .file-input-images::-webkit-scrollbar-track {
    background-color: var(--bg-white); }
  .file-input-images::-webkit-scrollbar-thumb {
    background-color: var(--elements-gray-secondary);
    border-radius: 62.4375rem;
    border: 3px solid var(--bg-white); }
    @media screen and (min-width: 160.0625rem) {
      .file-input-images::-webkit-scrollbar-thumb {
        border-radius: 39.02344vw;
        border-width: 0.11719vw; } }
  @media screen and (min-width: 160.0625rem) {
    .file-input-images {
      gap: 0.78125vw; } }
  .file-input-images .zoom-image {
    flex-shrink: 0; }

.text-input {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem; }
  @media screen and (min-width: 160.0625rem) {
    .text-input {
      gap: 0.19531vw; } }
  .text-input__subtitle {
    color: var(--text-dark-primary); }
  .text-input__input {
    padding: 0.5625rem 0.875rem;
    color: var(--text-dark-primary);
    font-size: 1rem;
    line-height: 1.5;
    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark);
    border-radius: var(--radius-common);
    transition: border-color var(--animation-timing) var(--cubic-bezier), background-color var(--animation-timing) var(--cubic-bezier), color var(--animation-timing) var(--cubic-bezier);
    outline: none;
    resize: none; }
    @media screen and (min-width: 160.0625rem) {
      .text-input__input {
        padding: 0.35156vw 0.54688vw;
        font-size: 0.625vw;
        border-width: 0.03906vw; } }
    @media screen and (max-width: 109.375rem) {
      .text-input__input {
        padding: 0.65625rem 0.875rem;
        font-size: 0.875rem; } }
    @media (any-hover: hover) {
      .text-input__input:hover {
        border-color: var(--accent-color); } }
    .text-input__input:focus {
      border-color: var(--accent-color); }
    .text-input__input:disabled {
      color: var(--text-disable);
      background-color: var(--bg-disable);
      border-color: var(--stroke-dark); }
      .text-input__input:disabled::placeholder {
        color: var(--text-disable); }
    .text-input__input::placeholder {
      color: var(--text-dark-tertiary); }

.common-input__icon {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  fill: var(--system-states-error);
  user-select: none; }
  @media screen and (min-width: 160.0625rem) {
    .common-input__icon {
      width: 0.78125vw;
      height: 0.78125vw; } }

.common-input__error {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  margin-top: -0.625rem;
  padding: 0.625rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  align-items: start;
  justify-content: start;
  background-color: var(--bg-white);
  box-shadow: 0px 4px 8px 4px rgba(142, 141, 208, 0.16);
  transition: margin-top var(--animation-timing) var(--cubic-bezier), opacity var(--animation-timing) var(--cubic-bezier), visibility var(--animation-timing) var(--cubic-bezier);
  pointer-events: none;
  opacity: 1;
  visibility: hidden; }
  @media screen and (min-width: 160.0625rem) {
    .common-input__error {
      margin-top: -0.39062vw;
      padding: 0.39062vw;
      gap: 0.39062vw;
      box-shadow: 0px 0.15625vw 0.3125vw 0.15625vw rgba(142, 141, 208, 0.16); } }

.common-input_error .common-input__error {
  margin-top: 0.625rem;
  opacity: 1 !important;
  visibility: visible;
  pointer-events: all; }
  @media screen and (min-width: 160.0625rem) {
    .common-input_error .common-input__error {
      margin-top: 0.39062vw; } }
  .common-input_error .common-input__error > .common-input__text.text {
    color: var(--text-dark-primary); }

.common-input_error > .text-input__input {
  border-color: var(--system-states-error); }

.select-input {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
  text-decoration: none; }
  .select-input_checkbox .select-answer__text {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    transition: color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .select-input_checkbox .select-answer__text {
        gap: 0.39062vw; } }
    .select-input_checkbox .select-answer__text .select-answer__checkbox {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.25rem;
      height: 1.25rem;
      background-color: var(--bg-white);
      border: solid 1px var(--stroke-dark);
      border-radius: var(--radius-secondary);
      transition: background-color var(--animation-timing) var(--cubic-bezier), border-color var(--animation-timing) var(--cubic-bezier); }
      @media screen and (min-width: 160.0625rem) {
        .select-input_checkbox .select-answer__text .select-answer__checkbox {
          width: 0.78125vw;
          height: 0.78125vw;
          border-width: 0.03906vw; } }
      .select-input_checkbox .select-answer__text .select-answer__checkbox:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: flex;
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6.66843 10.1139L12.7967 3.98566L13.7395 4.92846L6.66843 11.9995L2.42578 7.75691L3.36859 6.81411L6.66843 10.1139Z' fill='white'/%3E%3C/svg%3E");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat; }
        @media screen and (min-width: 160.0625rem) {
          .select-input_checkbox .select-answer__text .select-answer__checkbox:before {
            width: 0.625vw;
            height: 0.625vw; } }
  .select-input_checkbox .select-answer__input:checked ~ .select-answer__text {
    color: var(--accent-color); }
    .select-input_checkbox .select-answer__input:checked ~ .select-answer__text .select-answer__checkbox {
      background-color: var(--accent-color);
      border-color: var(--accent-color); }
  .select-input__subtitle {
    color: var(--text-dark-tertiary); }
  .select-input__checkbox:focus ~ .select-current, .select-input__checkbox:checked ~ .select-current {
    border-color: var(--accent-color); }
    .select-input__checkbox:focus ~ .select-current .select-current__icon, .select-input__checkbox:checked ~ .select-current .select-current__icon {
      transform: rotate(90deg); }
  .select-input__checkbox:focus ~ .select-answer, .select-input__checkbox:checked ~ .select-answer {
    margin-top: 0.625rem;
    opacity: 1 !important;
    visibility: visible; }
    @media screen and (min-width: 160.0625rem) {
      .select-input__checkbox:focus ~ .select-answer, .select-input__checkbox:checked ~ .select-answer {
        margin-top: 0.39062vw; } }
  .select-input__checkbox:disabled ~ .select-current {
    background-color: var(--bg-disable);
    border-color: var(--stroke-dark); }
    .select-input__checkbox:disabled ~ .select-current .select-current__text {
      color: var(--text-disable); }
    .select-input__checkbox:disabled ~ .select-current .select-current__icon {
      fill: var(--elements-gray); }
  .select-input.common-input_error > .select-input__current {
    border-color: var(--system-states-error); }

.select-current {
  padding: 0.5625rem 0.875rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.9375rem;
  background-color: var(--bg-white);
  border: solid 1px var(--stroke-dark);
  border-radius: var(--radius-common);
  transition: border-color var(--animation-timing) var(--cubic-bezier);
  cursor: pointer; }
  @media screen and (min-width: 160.0625rem) {
    .select-current {
      padding: 0.35156vw 0.54688vw;
      gap: 0.58594vw;
      border-width: 0.03906vw; } }
  @media screen and (max-width: 109.375rem) {
    .select-current {
      padding: 0.65625rem 0.875rem; } }
  @media (any-hover: hover) {
    .select-current:hover {
      border-color: var(--accent-color); } }
  .select-current_one {
    cursor: default; }
  .select-current__icon {
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
    fill: var(--elements-gray);
    transform: rotate(-90deg);
    user-select: none;
    transition: transform var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .select-current__icon {
        width: 0.625vw;
        height: 0.625vw; } }
  .select-current__text {
    width: 100%;
    max-width: 100%;
    display: block;
    color: var(--text-dark-primary);
    font-size: 1rem;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: color var(--animation-timing) var(--cubic-bezier);
    overflow: hidden;
    user-select: none; }
    @media screen and (min-width: 160.0625rem) {
      .select-current__text {
        font-size: 0.625vw; } }
    @media screen and (max-width: 109.375rem) {
      .select-current__text {
        font-size: 0.875rem; } }

.select-answer {
  position: absolute;
  z-index: 4;
  top: 100%;
  left: 0;
  padding: 0.625rem;
  margin-top: -0.625rem;
  width: 100%;
  min-width: 10rem;
  height: auto;
  max-height: 17.75rem;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-white);
  box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);
  border-radius: var(--radius-common);
  overflow: auto;
  transition: margin-top var(--animation-timing) var(--cubic-bezier), opacity var(--animation-timing) var(--cubic-bezier), visibility var(--animation-timing) var(--cubic-bezier);
  opacity: 0;
  visibility: hidden; }
  .select-answer::-webkit-scrollbar {
    width: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .select-answer::-webkit-scrollbar {
        width: 0.39062vw; } }
  .select-answer::-webkit-scrollbar-track {
    background: var(--bg-white); }
  .select-answer::-webkit-scrollbar-thumb {
    background-color: var(--elements-gray-secondary);
    border-radius: 62.4375rem;
    border: 3px solid var(--bg-white); }
    @media screen and (min-width: 160.0625rem) {
      .select-answer::-webkit-scrollbar-thumb {
        border-radius: 39.02344vw;
        border-width: 0.11719vw; } }
  @media screen and (min-width: 160.0625rem) {
    .select-answer {
      padding: 0.39062vw;
      margin-top: -0.39062vw;
      min-width: 6.25vw;
      max-height: 11.09375vw;
      box-shadow: 0px 0.15625vw 0.3125vw 0px rgba(142, 141, 208, 0.16); } }
  .select-answer__item {
    padding: 0.625rem 0.9375rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    color: var(--text-dark-primary);
    text-align: start;
    font-size: 1rem;
    line-height: 1.5;
    text-decoration: none;
    background-color: var(--bg-white);
    border: none;
    border-radius: var(--radius-common);
    transition: background-color var(--animation-timing) var(--cubic-bezier), color var(--animation-timing) var(--cubic-bezier);
    cursor: pointer; }
    @media screen and (min-width: 160.0625rem) {
      .select-answer__item {
        padding: 0.39062vw 0.58594vw;
        font-size: 0.625vw; } }
    @media screen and (max-width: 109.375rem) {
      .select-answer__item {
        padding: 0.71875rem 0.9375rem;
        font-size: 0.875rem; } }
    @media (any-hover: hover) {
      .select-answer__item:hover {
        background-color: var(--bg-light); } }
    .select-answer__item:focus, .select-answer__item:active {
      color: var(--accent-color);
      background-color: var(--bg-white); }

.tag {
  margin: 0;
  padding: 0.125rem 0.5rem;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-light-primary);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background: none;
  background-color: var(--background-color);
  border: none;
  border-radius: var(--radius-secondary);
  text-decoration: none;
  user-select: none;
  transition: background-color var(--animation-timing) var(--cubic-bezier); }
  @media screen and (min-width: 160.0625rem) {
    .tag {
      padding: 0.07812vw 0.3125vw;
      font-size: 0.54688vw; } }
  @media screen and (max-width: 109.375rem) {
    .tag {
      padding: 0.125rem 0.375rem;
      font-size: 0.75rem; } }
  @media (any-hover: hover) {
    .tag:hover {
      background-color: var(--background-color-hover); } }
  .tag_state-register {
    --background-color: #fff;
    --background-color-hover: #fff;
    --text-light-primary: var(--accent-color-dark);
    font-weight: 600;
    border: 1px solid var(--accent-color-dark);
    pointer-events: none; }

.user-avatar {
  padding: 0;
  margin: 0;
  width: 4.6875rem;
  height: 4.6875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: var(--bg-light);
  border-radius: 62.4375rem;
  overflow: hidden;
  user-select: none; }
  @media screen and (min-width: 160.0625rem) {
    .user-avatar {
      width: 2.92969vw;
      height: 2.92969vw;
      border-radius: 39.02344vw; } }
  @media screen and (max-width: 109.375rem) {
    .user-avatar {
      width: 3.75rem;
      height: 3.75rem; } }
  @media screen and (max-width: 39.375rem) {
    .user-avatar {
      width: 2.75rem;
      height: 2.75rem; } }
  .user-avatar__svg {
    width: 2.5rem;
    height: 2.5rem;
    fill: var(--text-disable); }
    @media screen and (min-width: 160.0625rem) {
      .user-avatar__svg {
        width: 1.5625vw;
        height: 1.5625vw; } }
    @media screen and (max-width: 109.375rem) {
      .user-avatar__svg {
        width: 1.875rem;
        height: 1.875rem; } }
    @media screen and (max-width: 39.375rem) {
      .user-avatar__svg {
        width: 1.5rem;
        height: 1.5rem; } }
  .user-avatar__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    user-select: none;
    pointer-events: none; }

.article {
  --table-border: #ededed; }
  .article .table-wrapper {
    overflow: auto;
    position: relative; }
  .article table {
    padding: 0;
    margin: 0;
    width: 100%;
    color: var(--text-dark-primary);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: var(--radius-common);
    border: solid 1px var(--table-border);
    border-collapse: collapse;
    /*@include mediaMobile {
      display: flex;
    }*/ }
    @media screen and (min-width: 160.0625rem) {
      .article table {
        font-size: 0.625vw;
        border-width: 0.03906vw; } }
    @media screen and (max-width: 109.375rem) {
      .article table {
        font-size: 0.875rem; } }
    .article table tbody {
      /*@include mediaMobile {
        width: 100%;
        display: flex;
        flex-direction: column;

        gap: rem(10);
      }*/ }
      .article table tbody td {
        /*@include mediaMobile {
          padding: 0;

          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          border: none;

          & > span,
          &::before {
            padding: rem(13) rem(15);

            width: 100%;
            display: flex;

            border: solid 1px var(--table-border);
          }

          & > span {
            border-width: 0 0 1px 1px;
          }

          &::before {
            border-width: 0 0 1px 0;
          }


          &:first-child {

            & > span,
            & > span.bt-content,
            &::before {
              --section-text-color-secondary: var(--text-light-primary);
              
              color: var(--text-light-primary);

              background-color: var(--elements-dark);
            }
          }

          &:last-child {

            & > span,
            &::before {
              border-bottom: none;
            }
          }
        }*/ }
    .article table tr {
      /* @include mediaMobile {
        width: 100%;
        display: flex;
        flex-direction: column;

        border: solid 1px var(--table-border);
        border-radius: var(--radius-common);

        overflow: hidden;
      }*/ }
    .article table td,
    .article table th {
      padding: 0.9375rem 1.25rem;
      border: solid 1px var(--table-border); }
      @media screen and (min-width: 160.0625rem) {
        .article table td,
        .article table th {
          padding: 0.58594vw 0.78125vw;
          border-width: 0.03906vw; } }
      @media screen and (max-width: 109.375rem) {
        .article table td,
        .article table th {
          padding: 1.03125rem 1.25rem; } }
      @media screen and (max-width: 87.5rem) {
        .article table td,
        .article table th {
          padding: 1.03125rem 0.9375rem; } }
    .article table th {
      text-align: start;
      font-weight: 600; }

.tab__content {
  padding: 0.53125rem 0.875rem;
  margin: 0;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  color: var(--text-dark-primary);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background: transparent;
  border: solid 1px var(--stroke-dark);
  border-radius: var(--radius-common);
  transition: color var(--animation-timing) var(--cubic-bezier), background-color var(--animation-timing) var(--cubic-bezier), border-color var(--animation-timing) var(--cubic-bezier);
  cursor: pointer;
  user-select: none; }
  @media screen and (min-width: 160.0625rem) {
    .tab__content {
      padding: 0.33203vw 0.54688vw;
      gap: 0.39062vw;
      font-size: 0.625vw;
      border-width: 0.03906vw; } }
  @media screen and (max-width: 109.375rem) {
    .tab__content {
      padding: 0.625rem 0.875rem;
      font-size: 0.875rem; } }
  @media (any-hover: hover) {
    .tab__content:hover {
      color: var(--accent-color); }
      .tab__content:hover > .tab__icon {
        fill: var(--accent-color); } }
  .tab__content:active {
    color: var(--accent-color);
    background-color: var(--bg-light);
    border-color: var(--bg-light); }
    .tab__content:active > .tab__icon {
      fill: var(--accent-color); }

.tab__input:checked ~ .tab__content {
  color: var(--accent-color);
  background-color: var(--bg-light);
  border-color: var(--bg-light); }
  .tab__input:checked ~ .tab__content > .tab__icon {
    fill: var(--accent-color); }

.tab__input:focus ~ .tab__content {
  color: var(--accent-color); }
  .tab__input:focus ~ .tab__content > .tab__icon {
    fill: var(--accent-color); }

.tab__icon {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  fill: var(--text-dark-primary);
  transition: fill var(--animation-timing) var(--cubic-bezier); }
  @media screen and (min-width: 160.0625rem) {
    .tab__icon {
      width: 0.78125vw;
      height: 0.78125vw; } }

.tab__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer; }
  @media (any-hover: hover) {
    .tab__button:hover > .tab__icon {
      fill: var(--accent-color); } }
  .tab__button > .tab__icon {
    fill: var(--text-dark-primary); }

.tab-content {
  display: none; }
  .tab-content.active {
    display: flex; }

ul.list,
ol.list,
.article ul,
.article ol {
  margin: 0;
  padding: 0;
  list-style: none;
  color: var(--section-text-color-secondary);
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5; }
  @media screen and (min-width: 160.0625rem) {
    ul.list,
    ol.list,
    .article ul,
    .article ol {
      font-size: 0.70312vw; } }
  @media screen and (max-width: 109.375rem) {
    ul.list,
    ol.list,
    .article ul,
    .article ol {
      font-size: 1rem; } }
  @media screen and (max-width: 39.375rem) {
    ul.list,
    ol.list,
    .article ul,
    .article ol {
      font-size: 0.875rem; } }
  ul.list li,
  ol.list li,
  .article ul li,
  .article ol li {
    padding-left: 0.9375rem;
    display: block; }
    @media screen and (min-width: 160.0625rem) {
      ul.list li,
      ol.list li,
      .article ul li,
      .article ol li {
        padding-left: 0.58594vw; } }
    @media screen and (max-width: 109.375rem) {
      ul.list li,
      ol.list li,
      .article ul li,
      .article ol li {
        padding-left: 0.625rem; } }
    ul.list li::before,
    ol.list li::before,
    .article ul li::before,
    .article ol li::before {
      margin-right: 0.9375rem; }
      @media screen and (max-width: 109.375rem) {
        ul.list li::before,
        ol.list li::before,
        .article ul li::before,
        .article ol li::before {
          margin-right: 0.625rem; } }
    ul.list li b,
    ul.list li strong,
    ol.list li b,
    ol.list li strong,
    .article ul li b,
    .article ul li strong,
    .article ol li b,
    .article ol li strong {
      color: var(--section-text-color-primary);
      font-weight: 600; }

ul.list li,
.article ul li {
  padding-left: 2.1875rem;
  text-indent: -1.25rem; }
  @media screen and (min-width: 160.0625rem) {
    ul.list li,
    .article ul li {
      padding-left: 1.36719vw;
      text-indent: -0.58594vw; } }
  @media screen and (max-width: 109.375rem) {
    ul.list li,
    .article ul li {
      padding-left: 1.5625rem;
      text-indent: -0.9375rem; } }
  ul.list li::before,
  .article ul li::before {
    content: "";
    position: relative;
    bottom: 0.25rem;
    width: 0.3125rem;
    height: 0.3125rem;
    display: inline-block;
    background-color: var(--accent-color);
    border-radius: 62.4375rem; }
    @media screen and (min-width: 160.0625rem) {
      ul.list li::before,
      .article ul li::before {
        bottom: 0.15625vw;
        width: 0.19531vw;
        height: 0.19531vw;
        border-radius: 39.02344vw; } }
    @media screen and (max-width: 109.375rem) {
      ul.list li::before,
      .article ul li::before {
        bottom: 0.1875rem; } }
    @media screen and (max-width: 39.375rem) {
      ul.list li::before,
      .article ul li::before {
        bottom: 0.125rem; } }

ol.list,
.article ol {
  list-style-type: none;
  counter-reset: num; }
  ol.list li,
  .article ol li {
    padding-left: 3.5rem;
    text-indent: -1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      ol.list li,
      .article ol li {
        padding-left: 2.1875vw;
        text-indent: -0.58594vw; } }
    @media screen and (max-width: 109.375rem) {
      ol.list li,
      .article ol li {
        padding-left: 2.6875rem;
        text-indent: -1rem; } }
    @media screen and (max-width: 39.375rem) {
      ol.list li,
      .article ol li {
        padding-left: 2.5rem;
        text-indent: -0.9375rem; } }
    ol.list li::before,
    .article ol li::before {
      display: inline-block;
      color: var(--accent-color);
      content: "0" counter(num) ".";
      counter-increment: num; }

.pagination {
  display: flex;
  flex-direction: row;
  gap: 0.3125rem; }
  @media screen and (min-width: 160.0625rem) {
    .pagination {
      gap: 0.19531vw; } }
  .pagination__list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    gap: 0.3125rem;
    list-style: none; }
    @media screen and (min-width: 160.0625rem) {
      .pagination__list {
        gap: 0.19531vw; } }
  .pagination__item_active .button {
    color: var(--accent-color);
    border-color: var(--accent-color); }
  .pagination__button {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400; }
    @media screen and (min-width: 160.0625rem) {
      .pagination__button {
        width: 1.71875vw;
        height: 1.71875vw;
        font-size: 0.625vw; } }
    @media screen and (max-width: 39.375rem) {
      .pagination__button {
        width: 2.125rem;
        height: 2.125rem; } }

.rating {
  position: relative;
  width: 8.125rem;
  height: 1.375rem;
  overflow: hidden; }
  @media screen and (min-width: 160.0625rem) {
    .rating {
      width: 5.07812vw;
      height: 0.85938vw; } }
  .rating__enable, .rating__disable {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    user-select: none; }
  .rating__enable {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: var(--rating-percent);
    max-width: 100%;
    fill: var(--system-states-validation);
    object-fit: fill;
    transition: width var(--animation-timing) var(--cubic-bezier); }
  .rating__disable {
    fill: var(--elements-gray-secondary); }
  .rating-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .rating-wrapper {
        gap: 0.39062vw; } }
    .rating-wrapper .text {
      color: var(--text-dark-tertiary); }
    .rating-wrapper_review .rating {
      cursor: pointer; }

.rating-form__wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.625rem; }
  @media screen and (min-width: 160.0625rem) {
    .rating-form__wrapper {
      gap: 0.39062vw; } }

.rating-form__list {
  display: flex;
  flex-direction: row;
  gap: 0.3125rem;
  cursor: pointer;
  user-select: none; }
  @media screen and (min-width: 160.0625rem) {
    .rating-form__list {
      gap: 0.19531vw; } }

.rating-form__star {
  width: 1.375rem;
  height: 1.375rem;
  display: flex;
  cursor: pointer; }
  @media screen and (min-width: 160.0625rem) {
    .rating-form__star {
      width: 0.85938vw;
      height: 0.85938vw; } }
  .rating-form__star.active svg {
    fill: var(--system-states-validation); }
  .rating-form__star svg {
    width: 100%;
    height: 100%;
    fill: var(--elements-gray-secondary);
    transition: fill var(--animation-timing) var(--cubic-bezier); }

.rating-form__text {
  color: var(--text-dark-tertiary); }

.availability {
  --availability-status-color: var(--system-states-success);
  position: relative;
  z-index: 1;
  padding: 0.125rem 0.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.3125rem;
  color: var(--availability-status-color);
  border-radius: var(--radius-common);
  user-select: none;
  overflow: hidden; }
  @media screen and (min-width: 160.0625rem) {
    .availability {
      padding: 0.07812vw 0.39062vw;
      gap: 0.19531vw; } }
  @media screen and (max-width: 109.375rem) {
    .availability {
      padding: 0.125rem 0.3125rem; } }
  .availability_out-stock {
    --availability-status-color: var(--system-states-error); }
  .availability_to-order {
    --availability-status-color: var(--main-secondary); }
  .availability_discontinued {
    --availability-status-color: var(--elements-gray); }
  .availability::before {
    content: "";
    width: 0.4375rem;
    height: 0.4375rem;
    background-color: var(--availability-status-color);
    border-radius: 10rem; }
    @media screen and (min-width: 160.0625rem) {
      .availability::before {
        width: 0.27344vw;
        height: 0.27344vw;
        border-radius: 6.25vw; } }
    @media screen and (max-width: 109.375rem) {
      .availability::before {
        width: 0.375rem;
        height: 0.375rem; } }
  .availability::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: var(--availability-status-color);
    opacity: 0.1; }

blockquote {
  margin: 0;
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  color: var(--text-dark-secondary);
  font-size: 1.125rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--bg-light);
  border-radius: var(--radius-common); }
  @media screen and (min-width: 160.0625rem) {
    blockquote {
      padding: 1.17188vw;
      gap: 0.78125vw;
      font-size: 0.70312vw; } }
  @media screen and (max-width: 109.375rem) {
    blockquote {
      gap: 0.9375rem;
      font-size: 1rem; } }
  @media screen and (max-width: 39.375rem) {
    blockquote {
      font-size: 0.875rem; } }
  blockquote::before {
    content: "";
    width: 1.875rem;
    height: 1.5rem;
    display: flex;
    background-color: var(--accent-color);
    mask-image: url("data:image/svg+xml,%3Csvg width='30' height='24' viewBox='0 0 30 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.0909 0C1.72727 3.04615 0 8.76923 0 14.1231C0 20.7692 3.81818 23.9077 7.54545 23.9077C8.30612 23.9547 9.06809 23.8403 9.78282 23.5718C10.4975 23.3032 11.1493 22.8865 11.6967 22.3481C12.244 21.8097 12.6749 21.1614 12.9619 20.4446C13.2489 19.7278 13.3857 18.9581 13.3636 18.1846C13.3636 14.5846 10.8182 12.8308 8.09091 12.4615C5.72727 12.1846 4.18182 11.0769 4.18182 8.30769C4.18182 6.55385 4.63636 3.6 11.5455 1.01538L11.0909 0ZM27.1818 0C17.8182 3.04615 16 8.76923 16 14.1231C16 20.7692 19.9091 23.9077 23.6364 23.9077C24.397 23.9547 25.159 23.8403 25.8737 23.5718C26.5884 23.3032 27.2402 22.8865 27.7876 22.3481C28.335 21.8097 28.7659 21.1614 29.0529 20.4446C29.3399 19.7278 29.4767 18.9581 29.4545 18.1846C29.4545 14.5846 26.9091 12.8308 24.1818 12.4615C21.8182 12.1846 20.1818 11.0769 20.1818 8.30769C20.1818 6.55385 20.7273 3.6 27.6364 1.01538L27.1818 0Z' fill='black'/%3E%3C/svg%3E%0A");
    mask-size: contain;
    mask-repeat: no-repeat; }

.breadcrumbs__list {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.625rem;
  list-style: none; }
  @media screen and (min-width: 160.0625rem) {
    .breadcrumbs__list {
      gap: 0.39062vw; } }

.breadcrumbs__item {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  gap: 0.625rem; }
  @media screen and (min-width: 160.0625rem) {
    .breadcrumbs__item {
      gap: 0.39062vw; } }
  .breadcrumbs__item:last-child::after {
    display: none; }
  .breadcrumbs__item:last-child .breadcrumbs__link {
    color: var(--text-dark-primary);
    pointer-events: none; }
  .breadcrumbs__item::after {
    content: "";
    width: 0.125rem;
    height: 0.125rem;
    display: flex;
    flex-shrink: 0;
    background-color: var(--text-dark-secondary);
    user-select: none; }

.breadcrumbs__link {
  position: relative;
  color: var(--text-dark-secondary);
  text-decoration: none;
  cursor: pointer;
  transition: color var(--animation-timing) var(--cubic-bezier); }
  @media (any-hover: hover) {
    .breadcrumbs__link:hover {
      color: var(--accent-color); } }
  .breadcrumbs__link::before {
    content: "";
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    width: calc(100% + 0.5rem * 2);
    height: calc(100% + 0.5rem * 2);
    background-color: transparent;
    opacity: 0; }
    @media screen and (min-width: 160.0625rem) {
      .breadcrumbs__link::before {
        top: -0.3125vw;
        left: -0.3125vw;
        width: calc(100% + 0.3125vw * 2);
        height: calc(100% + 0.3125vw * 2); } }

.section-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem; }
  @media screen and (min-width: 160.0625rem) {
    .section-title {
      gap: 0.78125vw; } }
  .section-title__block {
    display: flex;
    flex-direction: row;
    gap: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .section-title__block {
        gap: 0.78125vw; } }
  .section-title__navigation {
    display: flex;
    flex-direction: row;
    gap: 0.3125rem; }
    @media screen and (min-width: 160.0625rem) {
      .section-title__navigation {
        gap: 0.19531vw; } }
    @media screen and (max-width: 39.375rem) {
      .section-title__navigation {
        display: none; } }

.checkbox {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0.625rem;
  cursor: pointer;
  user-select: none; }
  @media screen and (min-width: 160.0625rem) {
    .checkbox {
      gap: 0.39062vw; } }
  @media (any-hover: hover) {
    .checkbox:hover .checkbox__item {
      border-color: var(--accent-color); } }
  .checkbox::before {
    content: "";
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    width: calc(100% + 0.5rem * 2);
    height: calc(100% + 0.5rem * 2);
    background-color: transparent;
    opacity: 0; }
    @media screen and (min-width: 160.0625rem) {
      .checkbox::before {
        top: -0.3125vw;
        left: -0.3125vw;
        width: calc(100% + 0.3125vw * 2);
        height: calc(100% + 0.3125vw * 2); } }
  .checkbox__input:checked ~ .checkbox__item {
    background-color: var(--accent-color);
    border-color: var(--accent-color); }
  .checkbox__item {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    display: block;
    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark);
    border-radius: var(--radius-secondary);
    transition: border-color var(--animation-timing) var(--cubic-bezier), background-color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .checkbox__item {
        width: 0.78125vw;
        height: 0.78125vw;
        border-width: 0.03906vw; } }
    .checkbox__item::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 1rem;
      height: 1rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6.66843 10.1139L12.7967 3.98566L13.7395 4.92846L6.66843 11.9995L2.42578 7.75691L3.36859 6.81411L6.66843 10.1139Z' fill='white'/%3E%3C/svg%3E");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat; }
      @media screen and (min-width: 160.0625rem) {
        .checkbox__item::before {
          width: 0.625vw;
          height: 0.625vw; } }
  .checkbox .link {
    position: relative;
    z-index: 1;
    color: var(--text-dark-primary);
    text-decoration: underline; }
    @media (any-hover: hover) {
      .checkbox .link:hover {
        color: var(--accent-color); } }

.radio {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0.625rem;
  cursor: pointer;
  user-select: none; }
  @media screen and (min-width: 160.0625rem) {
    .radio {
      gap: 0.39062vw; } }
  @media (any-hover: hover) {
    .radio:hover .radio__item {
      border-color: var(--accent-color); } }
  .radio::before {
    content: "";
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    width: calc(100% + 0.5rem * 2);
    height: calc(100% + 0.5rem * 2);
    background-color: transparent;
    opacity: 0; }
    @media screen and (min-width: 160.0625rem) {
      .radio::before {
        top: -0.3125vw;
        left: -0.3125vw;
        width: calc(100% + 0.3125vw * 2);
        height: calc(100% + 0.3125vw * 2); } }
  .radio__input:checked ~ .radio__item {
    background-color: var(--accent-color);
    border-color: var(--accent-color); }
  .radio__item {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    display: block;
    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark);
    border-radius: 10rem;
    transition: border-color var(--animation-timing) var(--cubic-bezier), background-color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .radio__item {
        width: 0.78125vw;
        height: 0.78125vw;
        border-width: 0.03906vw;
        border-radius: 6.25vw; } }
    .radio__item::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 0.625rem;
      height: 0.625rem;
      background-color: var(--bg-white);
      border-radius: 10rem; }
      @media screen and (min-width: 160.0625rem) {
        .radio__item::before {
          width: 0.39062vw;
          height: 0.39062vw;
          border-radius: 6.25vw; } }

.search {
  padding: 0.625rem 0.9375rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.9375rem;
  background-color: var(--bg-light);
  border-radius: var(--radius-common); }
  @media screen and (min-width: 160.0625rem) {
    .search {
      padding: 0.39062vw 0.58594vw;
      gap: 0.58594vw; } }
  @media screen and (max-width: 109.375rem) {
    .search {
      width: 100%; } }
  @media screen and (max-width: 39.375rem) {
    .search {
      padding: 0.4375rem;
      width: fit-content; } }
  .search__submit {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible; }
    .search__submit::before {
      content: "";
      position: absolute;
      top: -0.625rem;
      left: -0.625rem;
      width: calc(100% + 0.625rem * 2);
      height: calc(100% + 0.625rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .search__submit::before {
          top: -0.39062vw;
          left: -0.39062vw;
          width: calc(100% + 0.39062vw * 2);
          height: calc(100% + 0.39062vw * 2); } }
    @media (any-hover: hover) {
      .search__submit:hover > svg {
        fill: var(--accent-color); } }
  .search__icon {
    width: 1rem;
    height: 1rem;
    fill: var(--text-dark-secondary);
    transition: fill var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .search__icon {
        width: 0.625vw;
        height: 0.625vw; } }
    @media screen and (max-width: 39.375rem) {
      .search__icon {
        width: 1.25rem;
        height: 1.25rem;
        fill: var(--elements-dark); } }
  @media screen and (max-width: 109.375rem) {
    .search .text-input {
      flex-grow: 1; } }
  .search .text-input__input {
    width: 14.375rem;
    padding: 0;
    border: none;
    background-color: var(--bg-light); }
    @media screen and (min-width: 160.0625rem) {
      .search .text-input__input {
        width: 8.98438vw; } }
    @media screen and (max-width: 109.375rem) {
      .search .text-input__input {
        width: 100%; } }
    .search .text-input__input::placeholder {
      color: var(--text-dark-secondary); }

.user-menu {
  display: flex;
  align-items: stretch;
  justify-content: start;
  gap: 0.3125rem; }
  @media screen and (min-width: 160.0625rem) {
    .user-menu {
      gap: 0.19531vw; } }
  .user-menu__item {
    position: relative;
    padding: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.125rem;
    color: var(--text-dark-secondary);
    font-size: 0.625rem;
    text-decoration: none;
    line-height: 1;
    text-align: center;
    background-color: var(--bg-light);
    border-radius: var(--radius-common);
    border: none;
    cursor: pointer; }
    @media screen and (min-width: 160.0625rem) {
      .user-menu__item {
        padding: 0.46875vw;
        gap: 0.07812vw;
        font-size: 0.39062vw; } }
    @media (any-hover: hover) {
      .user-menu__item:hover > svg {
        fill: var(--accent-color); } }
    @media screen and (max-width: 39.375rem) {
      .user-menu__item {
        padding: 0.4375rem;
        height: 2.125rem; } }
  .user-menu__icon {
    width: 1.25rem;
    height: 1.25rem;
    fill: var(--elements-dark);
    transition: fill var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .user-menu__icon {
        width: 0.78125vw;
        height: 0.78125vw; } }
  .user-menu__search {
    display: none; }
    @media screen and (max-width: 39.375rem) {
      .user-menu__search {
        display: flex; } }
  .user-menu__count {
    width: 1.0625rem;
    height: 1.0625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-dark-secondary);
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 1.7;
    border-radius: 100rem;
    background-color: var(--header-count);
    transition: background-color var(--animation-timing) var(--cubic-bezier), color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .user-menu__count {
        width: 0.66406vw;
        height: 0.66406vw;
        font-size: 0.39062vw;
        border-radius: 62.5vw; } }
    @media screen and (max-width: 39.375rem) {
      .user-menu__count {
        position: absolute;
        top: 0.25rem;
        right: 0.25rem;
        width: 0.875rem;
        height: 0.875rem;
        font-size: 0.5rem; } }
    .user-menu__count_active {
      --text-dark-secondary: var(--text-light-primary);
      --header-count: var(--system-states-error); }

.price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.625rem; }
  @media screen and (min-width: 160.0625rem) {
    .price {
      gap: 0.39062vw; } }
  .price__old {
    color: var(--text-dark-secondary); }
    @media screen and (max-width: 39.375rem) {
      .price__old {
        font-size: 0.75rem; } }
  .price__sum {
    color: var(--text-dark-primary); }
    @media screen and (max-width: 39.375rem) {
      .price__sum {
        font-size: 1rem; } }

.section {
  width: 100%;
  position: relative;
  overflow: hidden; }
  .section .container {
    display: flex;
    flex-direction: column;
    gap: var(--section-gap); }
  .section .breadcrumbs {
    padding: 0.6875rem 0; }
    @media screen and (min-width: 160.0625rem) {
      .section .breadcrumbs {
        padding: 0.42969vw 0; } }
  .section_background {
    background-color: var(--section-background-color); }
  .section_text-light {
    --section-text-color-primary: var(--text-light-primary);
    --section-text-color-secondary: var(--text-light-secondary); }
  .section_common .container {
    gap: var(--section-gap-common); }
  .section_common:not(.section_padding-top):not(.section_breadcrumbs) {
    padding-top: var(--section-padding-common); }
  .section_padding-top {
    padding-top: var(--section-padding); }
  .section_padding-bottom {
    padding-bottom: var(--section-padding); }
  .section_padding-bottom-small {
    padding-bottom: var(--section-gap-common); }
  .section__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .section__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .section__head {
        gap: 0.78125vw; } }
    @media screen and (max-width: 87.5rem) {
      .section__head {
        gap: 3.125rem; } }
    @media screen and (max-width: 39.375rem) {
      .section__head {
        gap: 0.9375rem; } }
    .section__head_column {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      gap: 1.5625rem; }
      @media screen and (min-width: 160.0625rem) {
        .section__head_column {
          gap: 0.97656vw; } }
      @media screen and (max-width: 109.375rem) {
        .section__head_column {
          gap: 1.25rem; } }
      @media screen and (max-width: 87.5rem) {
        .section__head_column {
          gap: 0.9375rem; } }
  .section__image {
    width: 100%;
    height: 31.25rem;
    background-color: var(--bg-light);
    border-radius: var(--radius-common);
    overflow: hidden; }
    @media screen and (min-width: 131.25rem) {
      .section__image {
        height: auto;
        aspect-ratio: 2260/700; }
        @supports not (aspect-ratio: 1/1) {
          .section__image {
            height: 43.75rem; } } }
    @media screen and (min-width: 160.0625rem) {
      .section__image {
        height: 19.53125vw; } }
    @media screen and (max-width: 109.375rem) {
      .section__image {
        height: 25rem; } }
    @media screen and (max-width: 87.5rem) {
      .section__image {
        height: 18.75rem; } }
    @media screen and (max-width: 39.375rem) {
      .section__image {
        height: 10rem; } }
    .section__image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      pointer-events: none;
      user-select: none; }
  .section__title {
    width: 100%;
    color: var(--section-text-color-primary); }
  .section__navigation {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .section__navigation {
        gap: 0.78125vw; } }
    .section__navigation .button__content {
      white-space: nowrap; }
  .section__swiper-navigation {
    display: flex;
    flex-direction: row;
    gap: 0.3125rem; }
    @media screen and (min-width: 160.0625rem) {
      .section__swiper-navigation {
        gap: 0.19531vw; } }
    @media screen and (max-width: 39.375rem) {
      .section__swiper-navigation {
        display: none; } }
  .section__wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--section-wrapper-gap); }
    .section__wrapper_row {
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
    .section__wrapper .pagination {
      margin-left: auto;
      margin-right: auto; }

.short-product {
  margin-bottom: 1.25rem;
  padding: 1.25rem 0;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  gap: 0.9375rem;
  border-radius: 0;
  border: solid 1px var(--stroke-dark);
  border-width: 1px 0 1px 0; }
  @media screen and (min-width: 160.0625rem) {
    .short-product {
      margin-bottom: 0.78125vw;
      padding: 0.78125vw 0;
      gap: 0.58594vw;
      border-width: 0.03906vw 0 0.03906vw 0; } }
  @media screen and (max-width: 109.375rem) {
    .short-product {
      margin-bottom: 0.9375rem;
      padding: 0.9375rem 0; } }
  @media screen and (max-width: 87.5rem) {
    .short-product {
      margin-bottom: 1.25rem;
      padding: 1.25rem 0; } }
  @media screen and (max-width: 39.375rem) {
    .short-product {
      margin-bottom: 0.9375rem;
      padding: 0.9375rem 0 1.5625rem;
      flex-direction: column;
      gap: 0.625rem; } }
  .short-product__image {
    position: relative;
    padding: 0.3125rem;
    width: 9.375rem;
    height: 6.0625rem;
    display: flex;
    flex-shrink: 0;
    border-radius: var(--radius-common);
    overflow: hidden;
    user-select: none;
    pointer-events: none; }
    @media screen and (min-width: 160.0625rem) {
      .short-product__image {
        padding: 0.19531vw;
        width: 5.85938vw;
        height: 3.78906vw; } }
    @media screen and (max-width: 39.375rem) {
      .short-product__image {
        width: 100%;
        height: 18.75rem; } }
    @media screen and (max-width: 31.875rem) {
      .short-product__image {
        height: 15.625rem; } }
    @media screen and (max-width: 23.125rem) {
      .short-product__image {
        height: 12.5rem; } }
    @media screen and (max-width: 21.25rem) {
      .short-product__image {
        height: 10rem; } }
    .short-product__image img {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .short-product__text {
    color: var(--text-dark-primary);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  .short-product__tag-list {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    place-content: start;
    gap: 0.125rem; }
    @media screen and (min-width: 160.0625rem) {
      .short-product__tag-list {
        gap: 0.07812vw; } }
  .short-product__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.3125rem; }
    @media screen and (min-width: 160.0625rem) {
      .short-product__content {
        gap: 0.19531vw; } }
    @media screen and (max-width: 109.375rem) {
      .short-product__content {
        gap: 0.6875rem; } }
    @media screen and (max-width: 39.375rem) {
      .short-product__content {
        gap: 0.9375rem; } }
  .short-product__panel {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .short-product__panel {
        gap: 0.78125vw; } }
    @media screen and (max-width: 109.375rem) {
      .short-product__panel {
        align-items: end; } }
    @media screen and (max-width: 39.375rem) {
      .short-product__panel {
        width: 100%;
        justify-content: space-between; } }
    @media screen and (max-width: 109.375rem) {
      .short-product__panel .number-input {
        padding: 0.34375rem 0.5625rem;
        gap: 0.3125rem; } }
  @media screen and (max-width: 109.375rem) and (min-width: 160.0625rem) {
    .short-product__panel .number-input {
      padding: 0.21484vw 0.35156vw;
      gap: 0.19531vw; } }
    @media screen and (max-width: 109.375rem) {
        .short-product__panel .number-input .number-input__button::before {
          content: "";
          position: absolute;
          top: -0.3125rem;
          left: -0.3125rem;
          width: calc(100% + 0.3125rem * 2);
          height: calc(100% + 0.3125rem * 2);
          background-color: transparent;
          opacity: 0; } }
    @media screen and (max-width: 109.375rem) and (min-width: 160.0625rem) {
      .short-product__panel .number-input .number-input__button::before {
        top: -0.19531vw;
        left: -0.19531vw;
        width: calc(100% + 0.19531vw * 2);
        height: calc(100% + 0.19531vw * 2); } }
    @media screen and (max-width: 109.375rem) {
        .short-product__panel .number-input .number-input__input {
          height: 1.3125rem; } }
    @media screen and (max-width: 109.375rem) and (min-width: 160.0625rem) {
      .short-product__panel .number-input .number-input__input {
        height: 0.82031vw; } }

.product-collection {
  display: flex;
  flex-direction: column;
  gap: 0.625rem; }
  @media screen and (min-width: 160.0625rem) {
    .product-collection {
      gap: 0.39062vw; } }
  .product-collection__item {
    padding: 0.625rem 1.25rem 0.625rem 0.625rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 1.25rem;
    text-decoration: none;
    background-color: var(--bg-light);
    border-radius: var(--radius-common); }
    @media screen and (min-width: 160.0625rem) {
      .product-collection__item {
        padding: 0.39062vw 0.78125vw 0.39062vw 0.39062vw;
        gap: 0.78125vw; } }
    @media screen and (max-width: 109.375rem) {
      .product-collection__item {
        padding-right: 0.9375rem; } }
    @media screen and (max-width: 87.5rem) {
      .product-collection__item {
        gap: 0.9375rem; } }
    @media (any-hover: hover) {
      .product-collection__item:hover .product-collection__text {
        color: var(--accent-color); }
      .product-collection__item:hover .product-collection__icon {
        fill: var(--accent-color); } }
  .product-collection__icon {
    margin-left: auto;
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    fill: var(--elements-gray);
    transition: fill var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .product-collection__icon {
        width: 0.78125vw;
        height: 0.78125vw; } }
  .product-collection__image {
    width: 3.125rem;
    height: 3.125rem;
    flex-shrink: 0;
    border-radius: var(--radius-common);
    overflow: hidden; }
    @media screen and (min-width: 160.0625rem) {
      .product-collection__image {
        width: 1.95312vw;
        height: 1.95312vw; } }
    .product-collection__image img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      transition: transform var(--animation-timing-long) var(--cubic-bezier); }
  .product-collection__text {
    color: var(--text-dark-primary);
    transition: color var(--animation-timing) var(--cubic-bezier); }

.tooltip {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row; }
  .tooltip_active .tooltip__icon {
    fill: var(--accent-color); }
  .tooltip_active .tooltip__text {
    opacity: 1;
    visibility: visible;
    box-shadow: var(--shadow-small); }
  .tooltip__button {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    overflow: visible; }
    @media screen and (min-width: 160.0625rem) {
      .tooltip__button {
        width: 0.78125vw;
        height: 0.78125vw; } }
    .tooltip__button:before {
      content: "";
      position: absolute;
      top: -0.625rem;
      left: -0.625rem;
      width: calc(100% + 0.625rem * 2);
      height: calc(100% + 0.625rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .tooltip__button:before {
          top: -0.39062vw;
          left: -0.39062vw;
          width: calc(100% + 0.39062vw * 2);
          height: calc(100% + 0.39062vw * 2); } }
  .tooltip__icon {
    width: 100%;
    height: 100%;
    fill: var(--elements-gray-secondary);
    transition: fill var(--animation-timing) var(--cubic-bezier); }
  .tooltip__text {
    padding: 0.625rem;
    width: 21.875rem;
    color: var(--text-dark-primary);
    background-color: var(--bg-white);
    border-radius: var(--radius-common);
    border: solid 1px var(--stroke-dark);
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--animation-timing) var(--cubic-bezier), visibility var(--animation-timing) var(--cubic-bezier), box-shadow var(--animation-timing-long) var(--cubic-bezier);
    user-select: none;
    pointer-events: none; }
    @media screen and (min-width: 160.0625rem) {
      .tooltip__text {
        padding: 0.39062vw;
        width: 13.67188vw;
        border-width: 0.03906vw; } }
    @media screen and (max-width: 23.4375rem) {
      .tooltip__text {
        width: calc(100vw - (var(--container-offset) * 2)); } }

.contacts-social {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  list-style: none; }
  @media screen and (min-width: 160.0625rem) {
    .contacts-social {
      gap: 0.78125vw; } }
  .contacts-social__item {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem; }
    @media screen and (min-width: 160.0625rem) {
      .contacts-social__item {
        gap: 0.19531vw; } }
    @media screen and (max-width: 109.375rem) {
      .contacts-social__item {
        gap: 0.40625rem; } }
  .contacts-social__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .contacts-social__label {
        gap: 0.39062vw; } }
  .contacts-social__icon {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark);
    border-radius: var(--radius-common);
    user-select: none;
    pointer-events: none; }
    @media screen and (min-width: 160.0625rem) {
      .contacts-social__icon {
        width: 0.9375vw;
        height: 0.9375vw;
        border-width: 0.03906vw; } }
  .contacts-social__svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 0.75rem;
    height: 0.75rem;
    fill: var(--accent-color); }
    @media screen and (min-width: 160.0625rem) {
      .contacts-social__svg {
        width: 0.46875vw;
        height: 0.46875vw; } }
  .contacts-social__name {
    color: var(--section-text-color-secondary); }
  .contacts-social__text {
    color: var(--section-text-color-primary); }
    .contacts-social__text a {
      color: var(--section-text-color-primary);
      text-decoration: none;
      transition: color var(--animation-timing) var(--cubic-bezier); }
      @media (any-hover: hover) {
        .contacts-social__text a:hover {
          color: var(--accent-color); } }

.logo {
  width: fit-content;
  height: fit-content;
  display: flex;
  background-color: transparent;
  transition: opacity var(--animation-timing) var(--cubic-bezier);
  cursor: pointer; }
  @media (any-hover: hover) {
    .logo:hover {
      opacity: .9; } }
  .logo__image {
    width: 100%;
    height: 3.125rem;
    object-fit: contain;
    object-position: center; }
    @media screen and (min-width: 160.0625rem) {
      .logo__image {
        height: 1.95312vw; } }
    @media screen and (max-width: 109.375rem) {
      .logo__image {
        height: 2.75rem; } }
    @media screen and (max-width: 39.375rem) {
      .logo__image {
        width: auto;
        height: 2.125rem; } }

.link {
  padding: 0;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  transition: color var(--animation-timing) var(--cubic-bezier); }
  @media (any-hover: hover) {
    .link:hover {
      color: var(--accent-color); } }
  .link_accent {
    color: var(--accent-color); }
    @media (any-hover: hover) {
      .link_accent:hover {
        color: var(--accent-color-dark); } }

.swiper-wrapper {
  align-items: stretch; }

.swiper-slide {
  height: auto; }

.ya-map {
  width: 100%;
  height: 19.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-dark-secondary);
  background-color: var(--bg-light);
  border-radius: var(--radius-common);
  overflow: hidden;
  user-select: none; }
  @media screen and (min-width: 131.25rem) {
    .ya-map {
      height: auto;
      aspect-ratio: 1120/500; }
      @supports not (aspect-ratio: 1/1) {
        .ya-map {
          height: 31.25rem; } } }
  @media screen and (min-width: 160.0625rem) {
    .ya-map {
      height: 11.95312vw; } }
  @media screen and (max-width: 39.375rem) {
    .ya-map {
      height: 15.625rem; } }
  .ya-map .ymaps-2-1-79-controls__toolbar,
  .ya-map .ymaps-2-1-79-controls-pane,
  .ya-map .ymaps-2-1-79-map-copyrights-promo {
    display: none; }

.warning {
  display: block;
  width: 17.5rem;
  padding: 0.875rem 0.625rem;
  background-color: white;
  box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 1.875rem;
  left: 1.875rem;
  z-index: 200;
  color: #635f59;
  font-size: 0.75rem;
  transition: bottom var(--animation-timing-medium); }
  @media screen and (min-width: 160.0625rem) {
    .warning {
      width: 10.9375vw;
      padding: 0.54688vw 0.39062vw;
      box-shadow: 0 0 0.78125vw rgba(0, 0, 0, 0.1);
      bottom: 1.17188vw;
      left: 1.17188vw;
      font-size: 0.46875vw; } }
  .warning_close {
    position: absolute;
    display: block;
    right: 0.625rem;
    top: 0.625rem;
    width: 0.75rem;
    height: 0.625rem;
    border-radius: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #017ABB; }
    @media screen and (min-width: 160.0625rem) {
      .warning_close {
        right: 0.39062vw;
        top: 0.39062vw;
        width: 0.46875vw;
        height: 0.39062vw; } }
    .warning_close:before, .warning_close:after {
      content: '';
      display: block;
      width: 0.625rem;
      height: 0.0625rem;
      background-color: var(--accent-color);
      position: absolute;
      top: 50%;
      left: 50%; }
      @media screen and (min-width: 160.0625rem) {
        .warning_close:before, .warning_close:after {
          width: 0.39062vw;
          height: 0.03906vw; } }
    .warning_close:before {
      transform: translate(-50%, -50%) rotate(45deg); }
    .warning_close:after {
      transform: translate(-50%, -50%) rotate(-45deg); }
  .warning_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.9375rem; }
    @media screen and (min-width: 160.0625rem) {
      .warning_content {
        gap: 0.58594vw; } }
    .warning_content a {
      color: var(--accent-color); }
  .warning button {
    border: none;
    outline: none;
    display: block;
    background-color: var(--accent-color);
    color: #ffffff;
    padding: 0.6875rem 0.75rem 0.5625rem 0.75rem;
    font-size: 0.75rem;
    border-radius: 1.25rem;
    font-weight: bold;
    transition: all ease 0.3s;
    cursor: pointer; }
  .warning button:hover {
    color: #ffffff;
    background-color: var(--accent-color-dark); }
  .warning.cookie_scroll_up {
    bottom: 5rem; }
    @media screen and (min-width: 160.0625rem) {
      .warning.cookie_scroll_up {
        bottom: 3.125vw; } }

.article {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: var(--article-width);
  display: flex;
  flex-direction: column;
  gap: 1.5625rem; }
  .article_full-width {
    max-width: unset; }
  @media screen and (max-width: 39.375rem) {
    .article {
      gap: 0.9375rem; } }
  .article h1,
  .article h2,
  .article h3,
  .article h4,
  .article h5,
  .article h6,
  .article p,
  .article span:not(.tag),
  .article a:not(.tag) {
    margin: 0;
    padding: 0; }
  .article h1,
  .article h2,
  .article h3,
  .article h4,
  .article h5,
  .article h6 {
    color: var(--section-text-color-primary); }
  .article em {
    font-style: normal; }
  .article p,
  .article span:not(.tag):not(.button__content):not(.button__text) {
    color: var(--section-text-color-secondary);
    font-size: 1.125rem;
    line-height: 1.5; }
    @media screen and (min-width: 160.0625rem) {
      .article p,
      .article span:not(.tag):not(.button__content):not(.button__text) {
        font-size: 0.70312vw; } }
    @media screen and (max-width: 109.375rem) {
      .article p,
      .article span:not(.tag):not(.button__content):not(.button__text) {
        font-size: 1rem; } }
    @media screen and (max-width: 39.375rem) {
      .article p,
      .article span:not(.tag):not(.button__content):not(.button__text) {
        font-size: 0.875rem; } }
  .article ul,
  .article ol {
    display: flex;
    flex-direction: column;
    gap: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .article ul,
      .article ol {
        gap: 0.78125vw; } }
  .article__date {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.625rem;
    color: var(--section-text-color-secondary); }
    @media screen and (min-width: 160.0625rem) {
      .article__date {
        gap: 0.39062vw; } }
  .article__calendar {
    width: 1rem;
    height: 1rem;
    fill: var(--section-text-color-secondary); }
    @media screen and (min-width: 160.0625rem) {
      .article__calendar {
        width: 0.625vw;
        height: 0.625vw; } }
  .article__tag-list {
    margin-bottom: 0.3125rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .article__tag-list {
        margin-bottom: 0.19531vw;
        gap: 0.78125vw; } }
    @media screen and (max-width: 109.375rem) {
      .article__tag-list {
        margin-bottom: -0.3125rem; } }
    @media screen and (max-width: 39.375rem) {
      .article__tag-list {
        margin-bottom: 0;
        gap: 0.9375rem; } }

.fancybox__container {
  --fancybox-bg: rgba(0, 0, 0, 0.60); }
  .fancybox__container .fancybox__slide {
    padding: 3.125rem 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .fancybox__container .fancybox__slide {
        padding: 1.95312vw 1.25rem; } }
    @media screen and (max-width: 39.375rem) {
      .fancybox__container .fancybox__slide {
        padding: 0 !important; } }
    @media screen and (max-width: 62.5rem) {
      .fancybox__container .fancybox__slide.has-image {
        padding: 1.25rem !important; } }
    @media screen and (max-width: 39.375rem) {
      .fancybox__container .fancybox__slide.has-image {
        padding: 0.9375rem !important; } }
    .fancybox__container .fancybox__slide.has-image .fancybox__content {
      max-width: 67.125rem; }
      @media screen and (min-width: 160.0625rem) {
        .fancybox__container .fancybox__slide.has-image .fancybox__content {
          max-width: 45.85938vw; } }
      @media screen and (max-width: 109.375rem) {
        .fancybox__container .fancybox__slide.has-image .fancybox__content {
          max-width: 51.25rem; } }
    .fancybox__container .fancybox__slide.has-image img {
      border-radius: var(--radius-common);
      user-select: none; }
  .fancybox__container .fancybox__nav {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    padding: 0 var(--container-offset);
    width: 100%;
    max-width: calc(var(--container-width));
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    pointer-events: none; }
  .fancybox__container .f-button.is-next, .fancybox__container .f-button.is-prev {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: auto !important;
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--elements-dark);
    border-radius: var(--radius-common);
    border: solid 1px var(--stroke-dark);
    background-color: var(--bg-white);
    transition: color var(--animation-timing) var(--cubic-bezier);
    pointer-events: all;
    transform: translateY(0) !important; }
    @media screen and (min-width: 160.0625rem) {
      .fancybox__container .f-button.is-next, .fancybox__container .f-button.is-prev {
        width: 1.71875vw;
        height: 1.71875vw;
        border-width: 0.03906vw; } }
    @media screen and (max-width: 62.5rem) {
      .fancybox__container .f-button.is-next, .fancybox__container .f-button.is-prev {
        width: 2.125rem;
        height: 2.125rem; } }
    @media screen and (max-width: 39.375rem) {
      .fancybox__container .f-button.is-next, .fancybox__container .f-button.is-prev {
        width: 1.5rem;
        height: 1.5rem; } }
    @media (any-hover: hover) {
      .fancybox__container .f-button.is-next:hover, .fancybox__container .f-button.is-prev:hover {
        color: var(--accent-color); }
        .fancybox__container .f-button.is-next:hover::after, .fancybox__container .f-button.is-prev:hover::after {
          background-color: var(--accent-color); } }
    .fancybox__container .f-button.is-next:disabled, .fancybox__container .f-button.is-prev:disabled {
      fill: var(--text-disable);
      background-color: transparent; }
    .fancybox__container .f-button.is-next svg, .fancybox__container .f-button.is-prev svg {
      display: none; }
    .fancybox__container .f-button.is-next::before, .fancybox__container .f-button.is-prev::before {
      content: "";
      position: absolute;
      top: -0.375rem;
      left: -0.375rem;
      width: calc(100% + 0.375rem * 2);
      height: calc(100% + 0.375rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .fancybox__container .f-button.is-next::before, .fancybox__container .f-button.is-prev::before {
          top: -0.23438vw;
          left: -0.23438vw;
          width: calc(100% + 0.23438vw * 2);
          height: calc(100% + 0.23438vw * 2); } }
    .fancybox__container .f-button.is-next::after, .fancybox__container .f-button.is-prev::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 1.25rem;
      height: 1.25rem;
      background-color: var(--elements-dark);
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M9.11719 10.0924L13.2422 14.2174L12.0639 15.3957L6.76052 10.0924L12.0639 4.78906L13.2422 5.9674L9.11719 10.0924Z' fill='%23222222'/%3E%3C/svg%3E");
      mask-size: contain;
      mask-position: center;
      mask-repeat: no-repeat;
      transition: background-color var(--animation-timing) var(--cubic-bezier); }
      @media screen and (min-width: 160.0625rem) {
        .fancybox__container .f-button.is-next::after, .fancybox__container .f-button.is-prev::after {
          width: 0.78125vw;
          height: 0.78125vw; } }
  .fancybox__container .f-button.is-next {
    margin-right: 0 !important;
    transform: scale(-1, 1) !important; }
    @media screen and (max-width: 62.5rem) {
      .fancybox__container .f-button.is-next {
        margin-right: 1.25rem !important; } }
    @media screen and (max-width: 39.375rem) {
      .fancybox__container .f-button.is-next {
        margin-right: 0.625rem !important; } }
  .fancybox__container .f-button.is-prev {
    margin-left: 0 !important; }
    @media screen and (max-width: 62.5rem) {
      .fancybox__container .f-button.is-prev {
        margin-left: 1.25rem !important; } }
    @media screen and (max-width: 39.375rem) {
      .fancybox__container .f-button.is-prev {
        margin-left: 0.625rem !important; } }
  .fancybox__container.has-toolbar {
    --fancybox-bg: rgba(0, 0, 0, 0.85); }
    .fancybox__container.has-toolbar .fancybox__toolbar {
      top: 3.125rem; }
      @media screen and (min-width: 160.0625rem) {
        .fancybox__container.has-toolbar .fancybox__toolbar {
          top: 1.95312vw; } }
      @media screen and (max-width: 109.375rem) {
        .fancybox__container.has-toolbar .fancybox__toolbar {
          top: 2.5rem; } }
      @media screen and (max-width: 62.5rem) {
        .fancybox__container.has-toolbar .fancybox__toolbar {
          top: 1.25rem; } }
      .fancybox__container.has-toolbar .fancybox__toolbar .is-right {
        margin-right: 3.125rem; }
        @media screen and (min-width: 160.0625rem) {
          .fancybox__container.has-toolbar .fancybox__toolbar .is-right {
            margin-right: 1.95312vw; } }
        @media screen and (max-width: 109.375rem) {
          .fancybox__container.has-toolbar .fancybox__toolbar .is-right {
            margin-right: 2.5rem; } }
        @media screen and (max-width: 62.5rem) {
          .fancybox__container.has-toolbar .fancybox__toolbar .is-right {
            margin-right: 1.25rem; } }
        .fancybox__container.has-toolbar .fancybox__toolbar .is-right .f-button {
          width: 1.25rem;
          height: 1.25rem; }
          @media screen and (min-width: 160.0625rem) {
            .fancybox__container.has-toolbar .fancybox__toolbar .is-right .f-button {
              width: 0.78125vw;
              height: 0.78125vw; } }
          .fancybox__container.has-toolbar .fancybox__toolbar .is-right .f-button[data-fancybox-close] {
            background: transparent; }
            @media (any-hover: hover) {
              .fancybox__container.has-toolbar .fancybox__toolbar .is-right .f-button[data-fancybox-close]:hover::after {
                background-color: var(--accent-color); } }
            .fancybox__container.has-toolbar .fancybox__toolbar .is-right .f-button[data-fancybox-close] svg {
              display: none; }
            .fancybox__container.has-toolbar .fancybox__toolbar .is-right .f-button[data-fancybox-close]::before {
              content: "";
              position: absolute;
              top: -0.625rem;
              left: -0.625rem;
              width: calc(100% + 0.625rem * 2);
              height: calc(100% + 0.625rem * 2);
              background-color: transparent;
              opacity: 0; }
              @media screen and (min-width: 160.0625rem) {
                .fancybox__container.has-toolbar .fancybox__toolbar .is-right .f-button[data-fancybox-close]::before {
                  top: -0.39062vw;
                  left: -0.39062vw;
                  width: calc(100% + 0.39062vw * 2);
                  height: calc(100% + 0.39062vw * 2); } }
            .fancybox__container.has-toolbar .fancybox__toolbar .is-right .f-button[data-fancybox-close]::after {
              content: "";
              width: 100%;
              height: 100%;
              background-color: var(--elements-white);
              mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.4144 10.0002L16.4144 5.00015L15.0002 3.58594L10.0002 8.58594L5.00015 3.58594L3.58594 5.00015L8.58594 10.0002L3.58594 15.0002L5.00015 16.4144L10.0002 11.4144L15.0002 16.4144L16.4144 15.0002L11.4144 10.0002Z' fill='white'/%3E%3C/svg%3E");
              mask-size: contain;
              mask-position: center;
              mask-repeat: no-repeat;
              transition: background-color var(--animation-timing) var(--cubic-bezier); }

.fancybox__content .f-button.is-close-btn {
  top: 1.25rem !important;
  right: 1.25rem !important;
  width: 1.25rem;
  height: 1.25rem;
  background: none;
  opacity: 1; }
  @media screen and (min-width: 160.0625rem) {
    .fancybox__content .f-button.is-close-btn {
      top: 0.78125vw !important;
      right: 0.78125vw !important;
      width: 0.78125vw;
      height: 0.78125vw; } }
  @media screen and (max-width: 39.375rem) {
    .fancybox__content .f-button.is-close-btn {
      top: 0.9375rem !important;
      right: 0.9375rem !important; } }
  @media (any-hover: hover) {
    .fancybox__content .f-button.is-close-btn:hover::before, .fancybox__content .f-button.is-close-btn:hover::after {
      background-color: var(--accent-color);
      transform: rotate(0deg); } }
  .fancybox__content .f-button.is-close-btn svg {
    display: none; }
  .fancybox__content .f-button.is-close-btn::before, .fancybox__content .f-button.is-close-btn::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 1rem;
    height: 0.125rem;
    background-color: var(--elements-dark);
    transition: background-color var(--animation-timing) var(--cubic-bezier), transform var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .fancybox__content .f-button.is-close-btn::before, .fancybox__content .f-button.is-close-btn::after {
        width: 0.625vw;
        height: 0.07812vw; } }
  .fancybox__content .f-button.is-close-btn::before {
    transform: rotate(45deg); }
  .fancybox__content .f-button.is-close-btn::after {
    transform: rotate(-45deg); }

.modal-window {
  padding: 0;
  width: 100%;
  max-width: 38.75rem;
  border-radius: var(--radius-common);
  background-color: var(--bg-white);
  overflow: hidden;
  transition: opacity var(--animation-timing) var(--cubic-bezier); }
  @media screen and (min-width: 160.0625rem) {
    .modal-window {
      max-width: 24.21875vw; } }
  @media screen and (max-width: 109.375rem) {
    .modal-window {
      max-width: 37.5rem; } }
  @media screen and (max-width: 87.5rem) {
    .modal-window {
      max-width: 37.75rem; } }
  @media screen and (max-width: 39.375rem) {
    .modal-window {
      height: 100%;
      max-width: unset;
      border-radius: 0; } }
  .modal-window_successful {
    max-width: 32.875rem; }
    @media screen and (min-width: 160.0625rem) {
      .modal-window_successful {
        max-width: 20.54688vw; } }
    @media screen and (max-width: 109.375rem) {
      .modal-window_successful {
        max-width: 25rem; } }
    @media screen and (max-width: 87.5rem) {
      .modal-window_successful {
        max-width: 22.125rem; } }
    @media screen and (max-width: 39.375rem) {
      .modal-window_successful {
        max-width: unset; } }
    .modal-window_successful .modal-window__container {
      display: flex;
      align-items: center;
      justify-content: center; }
  .modal-window_quick-buy {
    max-width: 51.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .modal-window_quick-buy {
        max-width: 32.03125vw; } }
    @media screen and (max-width: 109.375rem) {
      .modal-window_quick-buy {
        max-width: 42.9375rem; } }
    @media screen and (max-width: 87.5rem) {
      .modal-window_quick-buy {
        max-width: 45.5rem; } }
  .modal-window_modal-cart {
    max-width: 51.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .modal-window_modal-cart {
        max-width: 32.03125vw; } }
    @media screen and (max-width: 109.375rem) {
      .modal-window_modal-cart {
        max-width: 42.9375rem; } }
    @media screen and (max-width: 87.5rem) {
      .modal-window_modal-cart {
        max-width: 45.5rem; } }
  .modal-window__container {
    padding: 2.5rem;
    height: 100%;
    overflow: auto; }
    @media screen and (min-width: 160.0625rem) {
      .modal-window__container {
        padding: 1.5625vw; } }
    @media screen and (max-width: 109.375rem) {
      .modal-window__container {
        padding: 1.875rem; } }
    @media screen and (max-width: 39.375rem) {
      .modal-window__container {
        padding: 1.5625rem 0.9375rem; } }
    .modal-window__container::-webkit-scrollbar {
      width: 0.625rem; }
      @media screen and (min-width: 160.0625rem) {
        .modal-window__container::-webkit-scrollbar {
          width: 0.39062vw; } }
    .modal-window__container::-webkit-scrollbar-track {
      background: var(--bg-white); }
    .modal-window__container::-webkit-scrollbar-thumb {
      background-color: var(--elements-gray-secondary);
      border-radius: 62.4375rem;
      border: 3px solid var(--bg-white); }
      @media screen and (min-width: 160.0625rem) {
        .modal-window__container::-webkit-scrollbar-thumb {
          border-radius: 39.02344vw;
          border-width: 0.11719vw; } }
  .modal-window__head {
    display: flex;
    flex-direction: column;
    gap: 0.9375rem; }
    @media screen and (min-width: 160.0625rem) {
      .modal-window__head {
        gap: 0.58594vw; } }
    .modal-window__head .text {
      color: var(--text-dark-secondary); }
  .modal-window__title {
    color: var(--text-dark-primary); }

.modal-action {
  position: relative;
  margin-top: 2.5rem;
  padding: 2.5rem 0 0;
  display: flex;
  flex-direction: column;
  gap: 1.5625rem; }
  @media screen and (min-width: 160.0625rem) {
    .modal-action {
      margin-top: 1.5625vw;
      padding: 1.5625vw 0 0;
      gap: 0.97656vw; } }
  @media screen and (max-width: 109.375rem) {
    .modal-action {
      margin-top: 1.875rem;
      padding-top: 1.875rem; } }
  @media screen and (max-width: 39.375rem) {
    .modal-action {
      margin-top: 1.5625rem;
      padding-top: 1.5625rem; } }
  .modal-action::before {
    content: "";
    position: absolute;
    top: 0;
    left: -2.5rem;
    width: calc(100% + (2.5rem * 2));
    height: 1px;
    background-color: var(--stroke-dark); }
    @media screen and (min-width: 160.0625rem) {
      .modal-action::before {
        left: -1.5625vw;
        width: calc(100% + (1.5625vw * 2)); } }
    @media screen and (max-width: 109.375rem) {
      .modal-action::before {
        left: -1.875rem;
        width: calc(100% + (1.875rem * 2)); } }
    @media screen and (max-width: 39.375rem) {
      .modal-action::before {
        left: -0.625rem;
        width: calc(100% + (0.625rem * 2)); } }
  .modal-action__form {
    display: flex;
    flex-direction: column;
    gap: 1.5625rem; }
    @media screen and (min-width: 160.0625rem) {
      .modal-action__form {
        gap: 0.97656vw; } }
  .modal-action__tab {
    position: relative;
    left: -2.5rem;
    padding: 0 2.5rem;
    width: calc(100% + (2.5rem * 2));
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    overflow-x: auto;
    overflow-y: hidden; }
    @media screen and (min-width: 160.0625rem) {
      .modal-action__tab {
        left: -1.5625vw;
        padding: 0 1.5625vw;
        width: calc(100% + (1.5625vw * 2));
        gap: 0.39062vw; } }
    @media screen and (max-width: 109.375rem) {
      .modal-action__tab {
        left: -1.875rem;
        padding: 0 1.875rem;
        width: calc(100% + (1.875rem * 2)); } }
    @media screen and (max-width: 39.375rem) {
      .modal-action__tab {
        left: -0.625rem;
        padding: 0 0.625rem;
        width: calc(100% + (0.625rem * 2)); } }
    .modal-action__tab::-webkit-scrollbar {
      height: 0.625rem; }
      @media screen and (min-width: 160.0625rem) {
        .modal-action__tab::-webkit-scrollbar {
          height: 0.39062vw; } }
    .modal-action__tab::-webkit-scrollbar-track {
      background-color: var(--bg-white); }
    .modal-action__tab::-webkit-scrollbar-thumb {
      background-color: var(--elements-gray-secondary);
      border-radius: 62.4375rem;
      border: 3px solid var(--bg-white); }
      @media screen and (min-width: 160.0625rem) {
        .modal-action__tab::-webkit-scrollbar-thumb {
          border-radius: 39.02344vw;
          border-width: 0.11719vw; } }
    .modal-action__tab .tab {
      width: 7.96875rem;
      flex-grow: 1; }
      @media screen and (min-width: 160.0625rem) {
        .modal-action__tab .tab {
          width: 4.98047vw; } }
      .modal-action__tab .tab__content {
        width: 100%; }
  .modal-action__submit {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .modal-action__submit {
        gap: 0.78125vw; } }
    .modal-action__submit .checkbox .text {
      color: var(--text-dark-secondary); }
    .modal-action__submit .button {
      width: 100%; }
  .modal-action__input .text-input {
    display: none; }
    .modal-action__input .text-input_active {
      display: flex; }
