.text {
  padding: 0;
  margin: 0;

  &_body {
    &-large {
      font-size: rem(18);
      line-height: 1.5;

      @include mediaBigDesktop {
        font-size: big(18);
      }

      @include mediaLaptop {
        font-size: rem(16);
      }

      @include mediaMobile {
        font-size: rem(14);
      }
    }

    &-medium {
      font-size: rem(16);
      line-height: 1.5;

      @include mediaBigDesktop {
        font-size: big(16);
      }

      @include mediaLaptop {
        font-size: rem(14);
      }
    }

    &-small {
      font-size: rem(14);
      line-height: 1.5;

      @include mediaBigDesktop {
        font-size: big(14);
      }

      @include mediaLaptop {
        font-size: rem(12);
      }
    }

    &-xs {
      font-size: rem(12);
      line-height: 1.5;

      @include mediaBigDesktop {
        font-size: big(12);
      }
    }
    
    &_semibold {
      font-weight: 600;
    }
  }

  &_caption {
    font-size: rem(14);
    line-height: 1.5;
    letter-spacing: 0.7px;
    text-transform: uppercase;

    @include mediaBigDesktop {
      font-size: big(14);
      letter-spacing: big(0.7);
    }

    @include mediaLaptop {
      font-size: rem(12);
      letter-spacing: rem(0.6);
    }
  }

  &_semibold {
    font-weight: 600;
  }

  &_regular {
    font-weight: 400;
  }

  &_italic {
    font-style: italic;
    font-weight: 400;
  }
}

.h1,
.h2,
.h3 {
  padding: 0;
  margin: 0;
}

.h1,
.article h1 {
  font-size: rem(38);
  font-weight: 600;
  line-height: 1.1;

  @include mediaBigDesktop {
    font-size: big(38);
  }

  @include mediaLaptop {
    font-size: rem(34);
  }

  @include mediaTablet {
    font-size: rem(26);
  }

  @include mediaMobile {
    font-size: rem(22);
  }
}

.h2,
.article h2 {
  font-size: rem(24);
  font-weight: 600;
  line-height: 1.2;

  @include mediaBigDesktop {
    font-size: big(24);
  }

  @include mediaLaptop {
    font-size: rem(22);
    line-height: 1.1;
  }

  @include mediaTablet {
    font-size: rem(20);
  }

  @include mediaMobile {
    font-size: rem(18);
  }
}

.h3,
.article h3,
.article h4,
.article h5,
.article h6 {
  font-size: rem(20);
  font-weight: 400;
  line-height: 1.4;

  @include mediaBigDesktop {
    font-size: big(20)
  }

  @include mediaLaptop {
    font-size: rem(18);
  }

  @include mediaMobile {
    font-size: rem(16);
  }
}
