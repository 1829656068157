@import '../../mixins';

.modal-window {
  padding: 0;

  width: 100%;
  max-width: rem(620);

  border-radius: var(--radius-common);
  background-color: var(--bg-white);
  overflow: hidden;
  transition: opacity var(--animation-timing) var(--cubic-bezier);

  @include mediaBigDesktop {
    max-width: big(620);
  }

  @include mediaLaptop {
    max-width: rem(600);
  }

  @include mediaTablet {
    max-width: rem(604);
  }

  @include mediaMobile {
    height: 100%;
    max-width: unset;

    border-radius: 0;
  }

  &_successful {
    max-width: rem(526);

    @include mediaBigDesktop {
      max-width: big(526);
    }

    @include mediaLaptop {
      max-width: rem(400);
    }

    @include mediaTablet {
      max-width: rem(354);
    }

    @include mediaMobile {
      max-width: unset;
    }

    & .modal-window {
      &__container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &_quick-buy {
    max-width: rem(820);

    @include mediaBigDesktop {
      max-width: big(820);
    }

    @include mediaLaptop {
      max-width: rem(687);
    }

    @include mediaTablet {
      max-width: rem(728);
    }
  }

  &_modal-cart {
    max-width: rem(820);

    @include mediaBigDesktop {
      max-width: big(820);
    }

    @include mediaLaptop {
      max-width: rem(687);
    }

    @include mediaTablet {
      max-width: rem(728);
    }
  }

  &__container {
    padding: rem(40);

    height: 100%;
    overflow: auto;

    @include mediaBigDesktop {
      padding: big(40);
    }

    @include mediaLaptop {
      padding: rem(30);
    }

    @include mediaMobile {
      padding: rem(25) rem(15);
    }

    @include scrollbar();
  }

  &__head {
    display: flex;
    flex-direction: column;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    } 

    & .text {
      color: var(--text-dark-secondary);
    }
  }

  &__title {
    color: var(--text-dark-primary);
  }
}
