* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

.page {
  height: 100%;

  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
}

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

::selection {
  background: var(--accent-color);
  color: var(--text-light-primary)
}

::-moz-selection {
  background: var(--accent-color);
  color: var(--text-light-primary)
}

.page__body {
  margin: 0;

  min-width: 320px;
  min-height: 100%;

  font-size: #{rem(16)};

  background-color: var(--bg-white);

  @include scrollbar();
}

.disable-scroll {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.wrapper {
  position: relative;
  z-index: 1;

  min-height: 100vh;
  display: flex;
  align-items: stretch;
  flex-direction: column;

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.footer {
  margin-top: auto;
}

.input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: var(--bg-white);

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[type=number] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);

  width: 100%;
  max-width: var(--container-width);
}

.reset-styles {
  padding: 0;
  margin: 0;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;

  transform: opacity var(--animation-timing) var(--cubic-bezier);
}

.elite-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  width: 100%;
  height: 100%;

  background-color: #000;
  opacity: 0;
  visibility: hidden;

  pointer-events: none;
  cursor: pointer;

  transition: opacity var(--animation-timing-medium) var(--cubic-bezier) var(--animation-timing),
    visibility var(--animation-timing-medium) var(--cubic-bezier) var(--animation-timing);

  &_show {
    opacity: 0.6;
    visibility: visible;

    pointer-events: all;
  }
}