@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open_Sans/Regular/OpenSans-Regular.woff2') format('woff2'),
    url('../fonts/Open_Sans/Regular/OpenSans-Regular.woff') format('woff'),
    url('../fonts/Open_Sans/Regular/OpenSans-Regular.eot') format('eot'),
    url('../fonts/Open_Sans/Regular/OpenSans-Regular.ttf') format('ttf'),
    url('../fonts/Open_Sans/Regular/OpenSans-Regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open_Sans/SemiBold/OpenSans-SemiBold.woff2') format('woff2'),
    url('../fonts/Open_Sans/SemiBold/OpenSans-SemiBold.woff') format('woff'),
    url('../fonts/Open_Sans/SemiBold/OpenSans-SemiBold.eot') format('eot'),
    url('../fonts/Open_Sans/SemiBold/OpenSans-SemiBold.ttf') format('ttf'),
    url('../fonts/Open_Sans/SemiBold/OpenSans-SemiBold.svg') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/Open_Sans/Italic/OpenSans-Italic.woff2') format('woff2'),
    url('../fonts/Open_Sans/Italic/OpenSans-Italic.woff') format('woff'),
    url('../fonts/Open_Sans/Italic/OpenSans-Italic.eot') format('eot'),
    url('../fonts/Open_Sans/Italic/OpenSans-Italic.ttf') format('ttf'),
    url('../fonts/Open_Sans/Italic/OpenSans-Italic.svg') format('svg');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
