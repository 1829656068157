.tab {
  &__content {
    padding: rem(8.5) rem(14);
    margin: 0;

    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(10);

    color: var(--text-dark-primary);
    font-size: rem(16);
    font-weight: 400;
    line-height: 1.5;

    background: transparent;
    border: solid 1px var(--stroke-dark);
    border-radius: var(--radius-common);

    transition: color var(--animation-timing) var(--cubic-bezier),
      background-color var(--animation-timing) var(--cubic-bezier),
      border-color var(--animation-timing) var(--cubic-bezier);

    cursor: pointer;
    user-select: none;

    @include mediaBigDesktop {
      padding: big(8.5) big(14);
      gap: big(10);

      font-size: big(16);

      border-width: big(1);
    }

    @include mediaLaptop {
      padding: rem(10) rem(14);

      font-size: rem(14);
    }

    @include hover {
      color: var(--accent-color);

      & > .tab__icon {
        fill: var(--accent-color);
      }
    }

    &:active {
      color: var(--accent-color);

      background-color: var(--bg-light);
      border-color: var(--bg-light);

      & > .tab__icon {
        fill: var(--accent-color);
      }
    }
  }

  &__input {
    &:checked {
      & ~ .tab__content {
        color: var(--accent-color);

        background-color: var(--bg-light);
        border-color: var(--bg-light);

        & > .tab__icon {
          fill: var(--accent-color);
        }
      }
    }

    &:focus {
      & ~ .tab__content {
        color: var(--accent-color);

        & > .tab__icon {
          fill: var(--accent-color);
        }
      }
    }
  }

  &__icon {
    width: rem(20);
    height: rem(20);
    flex-shrink: 0;

    fill: var(--text-dark-primary);

    transition: fill var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(20);
      height: big(20);
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;

    @include hover {
      & > .tab__icon {
        fill: var(--accent-color);
      }
    }

    & > .tab__icon {
      fill: var(--text-dark-primary);
    }
  }

  &-content {
    display: none;

    &.active {
      display: flex;
    }
  }
}
