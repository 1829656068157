.product-collection {
  display: flex;
  flex-direction: column;
  gap: rem(10);

  @include mediaBigDesktop {
    gap: big(10);
  }

  &__item {
    padding: rem(10) rem(20) rem(10) rem(10);

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: rem(20);

    text-decoration: none;

    background-color: var(--bg-light);
    border-radius: var(--radius-common);

    @include mediaBigDesktop {
      padding: big(10) big(20) big(10) big(10);
      gap: big(20);
    }

    @include mediaLaptop {
      padding-right: rem(15);
    }

    @include mediaTablet {
      gap: rem(15);
    }

    @include hover {
      & .product-collection {
        &__text {
          color: var(--accent-color);
        }
        &__icon {
          fill: var(--accent-color);
        }
        &__image {
          & img {
            //transform: scale(1.3);
          }
        }
      }
    }
  }

  &__icon {
    margin-left: auto;

    width: rem(20);
    height: rem(20);
    flex-shrink: 0;

    fill: var(--elements-gray);

    transition: fill var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(20);
      height: big(20);
    }
  }

  &__image {
    width: rem(50);
    height: rem(50);
    flex-shrink: 0;

    border-radius: var(--radius-common);
    overflow: hidden;

    @include mediaBigDesktop {
      width: big(50);
      height: big(50);
    }

    & img {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center;

      transition: transform var(--animation-timing-long) var(--cubic-bezier);
    }
  }

  &__text {
    color: var(--text-dark-primary);

    transition: color var(--animation-timing) var(--cubic-bezier);
  }
}
