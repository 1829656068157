.checkbox {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: rem(10);

  cursor: pointer;
  user-select: none;

  @include mediaBigDesktop {
    gap: big(10);
  }

  @include hover {
    & .checkbox {
      &__item {
        border-color: var(--accent-color);
      }
    }
  }

  &::before {
      @include pseudo(8) {}
  }

  &__input {
    &:checked {
      &~.checkbox {
        &__item {
          background-color: var(--accent-color);
          border-color: var(--accent-color);
        }
      }
    }
  }

  &__item {
    position: relative;
    width: rem(20);
    height: rem(20);
    display: block;

    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark);
    border-radius: var(--radius-secondary);

    transition: border-color var(--animation-timing) var(--cubic-bezier),
      background-color var(--animation-timing) var(--cubic-bezier);

      @include mediaBigDesktop {
        width: big(20);
        height: big(20);

        border-width: big(1);
      }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;

      width: rem(16);
      height: rem(16);

      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6.66843 10.1139L12.7967 3.98566L13.7395 4.92846L6.66843 11.9995L2.42578 7.75691L3.36859 6.81411L6.66843 10.1139Z' fill='white'/%3E%3C/svg%3E");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      @include mediaBigDesktop {
        width: big(16);
        height: big(16);
      }
    }
  }

  & .link {
    position: relative;
    z-index: 1;
    
    color: var(--text-dark-primary);
    text-decoration: underline;

    @include hover {
      color: var(--accent-color);
    }
  }
}
