.rating-form {

  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    gap: rem(5);

    cursor: pointer;
    user-select: none;

    @include mediaBigDesktop {
      gap: big(5);
    }
  }

  &__star {
    width: rem(22);
    height: rem(22);
    display: flex;

    cursor: pointer;

    @include mediaBigDesktop {
      width: big(22);
      height: big(22);
    }

    &.active {
      & svg {
        fill: var(--system-states-validation);
      }
    }

    & svg {
      width: 100%;
      height: 100%;

      fill: var(--elements-gray-secondary);

      transition: fill var(--animation-timing) var(--cubic-bezier);
    }
  }

  &__text {
    color: var(--text-dark-tertiary);
  }
}
