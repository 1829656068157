.user-menu {
  display: flex;
  align-items: stretch;
  justify-content: start;
  gap: rem(5);

  @include mediaBigDesktop {
    gap: big(5);
  }

  &__item {
    position: relative;
    padding: rem(12);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(2);

    color: var(--text-dark-secondary);
    font-size: rem(10);
    text-decoration: none;
    line-height: 1;
    text-align: center;

    background-color: var(--bg-light);
    border-radius: var(--radius-common);
    border: none;

    cursor: pointer;

    @include mediaBigDesktop {
      padding: big(12);
      gap: big(2);

      font-size: big(10);
    }

    @include hover {
      & > svg {
        fill: var(--accent-color);
      }
    }

    @include mediaMobile {
      padding: rem(7);

      height: rem(34);
    }
  }

  &__icon {
    width: rem(20);
    height: rem(20);

    fill: var(--elements-dark);

    transition: fill var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(20);
      height: big(20);
    }
  }

  &__search {
    display: none;

    @include mediaMobile {
      display: flex;
    }
  }

  &__count {
    width: rem(17);
    height: rem(17);
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--text-dark-secondary);
    font-size: rem(10);
    font-weight: 600;
    line-height: 1.7;

    border-radius: rem(1600);
    background-color: var(--header-count);

    transition: background-color var(--animation-timing) var(--cubic-bezier),
      color var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(17);
      height: big(17);

      font-size: big(10);

      border-radius: big(1600);
    }

    @include mediaMobile {
      position: absolute;
      top: rem(4);
      right: rem(4);

      width: rem(14);
      height: rem(14);
      
      font-size: rem(8);
    }

    &_active {
      --text-dark-secondary: var(--text-light-primary);
      --header-count: var(--system-states-error);
    }
  }
}
