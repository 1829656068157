.number-input {
  padding: rem(9) rem(14);

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: rem(10);

  border: solid 1px var(--stroke-dark);
  border-radius: var(--radius-common);

  @include mediaBigDesktop {
    padding: big(9) big(14);
    gap: big(10);
  }

  &_medium {
    @include mediumNumberInput
  }

  &__button {
    position: relative;

    width: fit-content;
    height: fit-content;
    display: flex;

    overflow: visible;

    &::before {
      @include pseudo(10) {}
    }

    @include hover {
      & .number-input {
        &__icon {
          fill: var(--accent-color);
        }
      }
    }
  }

  &__icon {
    width: rem(20);
    height: rem(20);

    fill: var(--elements-dark);

    transition: fill var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(20);
      height: big(20);
    }
  }

  &__input {
    padding: 0;
    margin: 0;

    width: rem(25);
    height: rem(24);

    text-align: center;
    font-weight: 600;

    outline: none;

    transition: color var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(25);
      height: big(24);
    }
  }

  &.disabled {
    opacity: 1;

    & .number-input {
      &__input {
        color: var(--text-disable);
      }

      &__icon {
        fill: var(--text-disable);
      }
    }
  }
}
