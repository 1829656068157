.pagination {
  display: flex;
  flex-direction: row;
  gap: rem(5);

  @include mediaBigDesktop {
    gap: big(5);
  }

  &__list {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    gap: rem(5);

    list-style: none;

    @include mediaBigDesktop {
      gap: big(5);
    }
  }

  &__item {
    &_active {
      & .button {
        color: var(--accent-color);

        border-color: var(--accent-color);
      }
    }
  }

  &__button {
    width: rem(44);
    height: rem(44);

    font-size: rem(16);
    line-height: 1.5;
    font-weight: 400;

    @include mediaBigDesktop {
      width: big(44);
      height: big(44);

      font-size: big(16);
    }

    @include mediaMobile {
      width: rem(34);
      height: rem(34);
    }
  }
}
