.search {
  padding: rem(10) rem(15);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: rem(15);

  background-color: var(--bg-light);
  border-radius: var(--radius-common);

  @include mediaBigDesktop {
    padding: big(10) big(15);
    gap: big(15);
  }

  @include mediaLaptop {
    width: 100%;
  }

  @include mediaMobile {
    padding: rem(7);
    width: fit-content;
  }

  &__submit {
    position: relative;

    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: visible;

    &::before {
      @include pseudo(10) {}
    }

    @include hover {
      &>svg {
        fill: var(--accent-color);
      }
    }
  }

  &__icon {
    width: rem(16);
    height: rem(16);

    fill: var(--text-dark-secondary);

    transition: fill var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(16);
      height: big(16);
    }

    @include mediaMobile {
      width: rem(20);
      height: rem(20);

      fill: var(--elements-dark);
    }
  }

  & .text-input {
    @include mediaLaptop {
      flex-grow: 1;
    }

    &__input {
      width: rem(230);
      padding: 0;

      border: none;
      background-color: var(--bg-light);

      @include mediaBigDesktop {
        width: big(230);
      }

      @include mediaLaptop {
        width: 100%;
      }

      &::placeholder {
        color: var(--text-dark-secondary);
      }
    }
  }
}
