.availability {
  --availability-status-color: var(--system-states-success);
  
  position: relative;
  z-index: 1;
  padding: rem(2) rem(10);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: rem(5);

  color: var(--availability-status-color);

  border-radius: var(--radius-common);

  user-select: none;
  overflow: hidden;

  @include mediaBigDesktop {
    padding: big(2) big(10);

    gap: big(5);
  }

  @include mediaLaptop {
    padding: rem(2) rem(5);
  }

  &_out-stock {
    --availability-status-color: var(--system-states-error);
  }

  &_to-order {
    --availability-status-color: var(--main-secondary);
  }
  
  &_discontinued {
    --availability-status-color: var(--elements-gray);
  }

  &::before {
    content: "";

    width: rem(7);
    height: rem(7);

    background-color: var(--availability-status-color);
    border-radius: rem(160);

    @include mediaBigDesktop {
      width: big(7);
      height: big(7);

      border-radius: big(160);
    }

    @include mediaLaptop {
      width: rem(6);
      height: rem(6);
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    background-color: var(--availability-status-color);
    opacity: 0.1;
  }
}
