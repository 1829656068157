.tooltip {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: row;

  &_active {
    & .tooltip {
      &__icon {
        fill: var(--accent-color);
      }

      &__text {
        opacity: 1;
        visibility: visible;

        box-shadow: var(--shadow-small);
      }
    }
  }

  &__button {
    position: relative;

    width: rem(20);
    height: rem(20);

    overflow: visible;

    @include mediaBigDesktop {
      width: big(20);
      height: big(20);
    }

    &:before {
      @include pseudo(10)
    }
  }

  &__icon {
    width: 100%;
    height: 100%;

    fill: var(--elements-gray-secondary);

    transition: fill var(--animation-timing) var(--cubic-bezier);
  }

  &__text {
    padding: rem(10);

    width: rem(350);

    color: var(--text-dark-primary);

    background-color: var(--bg-white);
    border-radius: var(--radius-common);
    border: solid 1px var(--stroke-dark);
    opacity: 0;
    visibility: hidden;

    transition: opacity var(--animation-timing) var(--cubic-bezier),
      visibility var(--animation-timing) var(--cubic-bezier),
      box-shadow var(--animation-timing-long) var(--cubic-bezier);

    user-select: none;
    pointer-events: none;

    @include mediaBigDesktop {
      padding: big(10);
      width: big(350);

      border-width: big(1);
    }

    @include media(#{rem(375)}) {
      width: calc(100vw - (var(--container-offset) * 2));
    }
  }
}
