.short-product {
  margin-bottom: rem(20);
  padding: rem(20) 0;

  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  gap: rem(15);

  border-radius: 0;
  border: solid 1px var(--stroke-dark);
  border-width: 1px 0 1px 0;

  @include mediaBigDesktop {
    margin-bottom: big(20);
    padding: big(20) 0;

    gap: big(15);

    border-width: big(1) 0 big(1) 0;
  }

  @include mediaLaptop {
    margin-bottom: rem(15);
    padding: rem(15) 0;
  }

  @include mediaTablet {
    margin-bottom: rem(20);
    padding: rem(20) 0;
  }

  @include mediaMobile {
    margin-bottom: rem(15);
    padding: rem(15) 0 rem(25);

    flex-direction: column;
    gap: rem(10);
  }

  &__image {
    position: relative;
    padding: rem(5);

    width: rem(150);
    height: rem(97);
    display: flex;
    flex-shrink: 0;

    border-radius: var(--radius-common);
    overflow: hidden;

    user-select: none;
    pointer-events: none;

    @include mediaBigDesktop {
      padding: big(5);

      width: big(150);
      height: big(97);
    }

    @include mediaMobile {
      width: 100%;
      height: rem(300);
    }

    @include media(#{rem(510)}) {
      height: rem(250);
    }

    @include media(#{rem(370)}) {
      height: rem(200);
    }

    @include media(#{rem(340)}) {
      height: rem(160);
    }

    & img {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;
    }
  }

  &__text {
    color: var(--text-dark-primary);

    display: -webkit-box;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    overflow: hidden;
  }

  &__tag-list {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    place-content: start;
    gap: rem(2);

    @include mediaBigDesktop {
      gap: big(2);
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(5);

    @include mediaBigDesktop {
      gap: big(5);
    }

    @include mediaLaptop {
      gap: rem(11);
    }

    @include mediaMobile {
      gap: rem(15);
    }
  }

  &__panel {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaLaptop {
      align-items: end;
    }

    @include mediaMobile {
      width: 100%;
      justify-content: space-between;
    }

    & .number-input {
      @include mediaLaptop {
        @include mediumNumberInput
      }
    }
  }
}
