.user-avatar {
  padding: 0;
  margin: 0;

  width: rem(75);
  height: rem(75);
  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;

  background-color: var(--bg-light);
  border-radius: rem(999);

  overflow: hidden;
  user-select: none;

  @include mediaBigDesktop {
    width: big(75);
    height: big(75);

    border-radius: big(999);
  }

  @include mediaLaptop {
    width: rem(60);
    height: rem(60);
  }

  @include mediaMobile() {
    width: rem(44);
    height: rem(44);
  }

  &__svg {
    width: rem(40);
    height: rem(40);

    fill: var(--text-disable);

    @include mediaBigDesktop {
      width: big(40);
      height: big(40);
    }

    @include mediaLaptop {
      width: rem(30);
      height: rem(30);
    }

    @include mediaMobile() {
      width: rem(24);
      height: rem(24);
    }
  }

  &__image {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;

    user-select: none;
    pointer-events: none;
  }
}
