@mixin mediumNumberInput {
  padding: rem(5.5) rem(9);

  gap: rem(5);

  @include mediaBigDesktop {
    padding: big(5.5) big(9);

    gap: big(5);
  }

  & .number-input {
    &__button {
      &::before {
        @include pseudo(5) {}
      }
    }

    &__input {
      height: rem(21);

      @include mediaBigDesktop {
        height: big(21);
      }
    }
  }

  @content;
}
