.link {
  padding: 0;
  margin: 0;

  text-decoration: none;

  cursor: pointer;

  transition: color var(--animation-timing) var(--cubic-bezier);

  @include hover {
    color: var(--accent-color);
  }

  &_accent {
    color: var(--accent-color);

    @include hover {
      color: var(--accent-color-dark);
    }
  }
}
