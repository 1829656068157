:root {
	--font-primary: "Open Sans", sans-serif;
	--content-width: #{rem(2260)};
	--container-offset: #{rem(150)};
	--container-width: calc(var(--content-width) + (var(--container-offset) * 2));

	@include mediaBigDesktop {
		--content-width: #{big(2260)};
		--container-offset: #{big(150)};
	}

	@include mediaTablet {
		--container-offset: #{rem(20)};
	}

	@include mediaMobile {
		--container-offset: #{rem(15)};
	}

	// article
	--article-width: #{rem(1074)};

	@include mediaBigDesktop {
		--article-width: #{big(1074)};
	}

	@include mediaLaptop {
		--article-width: #{rem(820)};
	}

	// shadow
	--shadow-large: 0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 10px 24px 0px rgba(0, 0, 0, 0.04), 0px 15px 32px 0px rgba(0, 0, 0, 0.04);
	--shadow-small: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);

	--cubic-bezier: cubic-bezier(.25, .1, .25, 1);
	--animation-timing: 0.45s;
	--animation-timing-medium: 0.7s;
	--animation-timing-long: 1s;

	// section padding
	--section-padding: #{rem(75)};
	--section-padding-common: #{rem(50)};
	--section-gap: #{rem(40)};
	--section-gap-common: #{rem(50)};
	--section-wrapper-gap: #{rem(35)};
	--section-text-color-primary: var(--text-dark-primary);
	--section-text-color-secondary: var(--text-dark-secondary);
	--section-background-color: var(--bg-light);
	--section-hidden-color: var(--bg-light);

	@include mediaDesktop {
		--section-padding: #{rem(100)};
		--section-padding-common: #{rem(50)};
		--section-gap: #{rem(40)};
		--section-gap-common: #{rem(50)};
		--section-wrapper-gap: #{rem(35)};
	}

	@include mediaBigDesktop {
		--section-padding: #{big(100)};
		--section-padding-common: #{big(50)};
		--section-gap: #{big(40)};
		--section-gap-common: #{big(50)};
		--section-wrapper-gap: #{big(35)};
	}

	@include mediaLaptop {
		--section-padding: #{rem(60)};
		--section-padding-common: #{rem(40)};
		--section-gap: #{rem(30)};
		--section-gap-common: #{rem(40)};
		--section-wrapper-gap: #{rem(30)};
	}

	@include mediaTablet {
		--section-padding-common: #{rem(30)};
		--section-gap-common: #{rem(30)};
	}

	@include mediaMobile {
		--section-padding: #{rem(40)};
		--section-padding-common: #{rem(25)};
		--section-gap: #{rem(20)};
		--section-gap-common: #{rem(25)};
		--section-wrapper-gap: #{rem(25)};
	}

	--radius-common: #{rem(4)};
	--radius-secondary: #{rem(3)};

	@include mediaBigDesktop {
		--radius-common: #{big(4)};
		--radius-secondary: #{big(3)};
	}

	--grid-column-gap: #{rem(20)};
	--grid-row-gap: #{rem(20)};

	@include mediaBigDesktop {
		--grid-column-gap: #{big(20)};
		--grid-row-gap: #{big(20)};
	}

	@include mediaMobile {
		--grid-column-gap: #{rem(15)};
		--grid-row-gap: #{rem(15)};
	}
}
