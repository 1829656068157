.contacts-social {
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  gap: rem(20);

  list-style: none;

  @include mediaBigDesktop {
    gap: big(20);
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: rem(5);

    @include mediaBigDesktop {
      gap: big(5);
    }

    @include mediaLaptop {
      gap: rem(6.5);
    }
  }

  &__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }
  }

  &__icon {
    position: relative;

    width: rem(24);
    height: rem(24);

    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark);
    border-radius: var(--radius-common);

    user-select: none;
    pointer-events: none;

    @include mediaBigDesktop {
      width: big(24);
      height: big(24);

      border-width: big(1);
    }
  }

  &__svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    width: rem(12);
    height: rem(12);

    fill: var(--accent-color);

    @include mediaBigDesktop {
      width: big(12);
      height: big(12);
    }
  }

  &__name {
    color: var(--section-text-color-secondary);
  }

  &__text {
    color: var(--section-text-color-primary);

    & a {
      color: var(--section-text-color-primary);
      text-decoration: none;

      transition: color var(--animation-timing) var(--cubic-bezier);

      @include hover {
        color: var(--accent-color);
      }
    }
  }
}
