.article {
  --table-border: #ededed;
  
  .table-wrapper {
    overflow: auto;
    position: relative;
  }

  & table {
    padding: 0;
    margin: 0;

    width: 100%;

    color: var(--text-dark-primary);
    font-size: rem(16);
    font-weight: 400;
    line-height: 1.5;

    border-radius: var(--radius-common);
    border: solid 1px var(--table-border);
    border-collapse: collapse;

    @include mediaBigDesktop {
      font-size: big(16);

      border-width: big(1);
    }

    @include mediaLaptop {
      font-size: rem(14);
    }

    /*@include mediaMobile {
      display: flex;
    }*/

    & tbody {
      /*@include mediaMobile {
        width: 100%;
        display: flex;
        flex-direction: column;

        gap: rem(10);
      }*/

      & td {
        /*@include mediaMobile {
          padding: 0;

          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          border: none;

          & > span,
          &::before {
            padding: rem(13) rem(15);

            width: 100%;
            display: flex;

            border: solid 1px var(--table-border);
          }

          & > span {
            border-width: 0 0 1px 1px;
          }

          &::before {
            border-width: 0 0 1px 0;
          }


          &:first-child {

            & > span,
            & > span.bt-content,
            &::before {
              --section-text-color-secondary: var(--text-light-primary);
              
              color: var(--text-light-primary);

              background-color: var(--elements-dark);
            }
          }

          &:last-child {

            & > span,
            &::before {
              border-bottom: none;
            }
          }
        }*/
      }
    }

    & tr {
     /* @include mediaMobile {
        width: 100%;
        display: flex;
        flex-direction: column;

        border: solid 1px var(--table-border);
        border-radius: var(--radius-common);

        overflow: hidden;
      }*/
    }

    & td,
    & th {
      padding: rem(15) rem(20);

      border: solid 1px var(--table-border);

      @include mediaBigDesktop {
        padding: big(15) big(20);

        border-width: big(1);
      }

      @include mediaLaptop {
        padding: rem(16.5) rem(20);
      }

      @include mediaTablet {
        padding: rem(16.5) rem(15);
      }
    }

    & th {
      //color: var(--text-light-primary);
      text-align: start;
      font-weight: 600;

      //background-color: var(--elements-dark);
    }
  }
}
