.section-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: rem(20);

  @include mediaBigDesktop {
    gap: big(20);
  }

  &__block {
    display: flex;
    flex-direction: row;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }
  }

  &__navigation {
    display: flex;
    flex-direction: row;
    gap: rem(5);

    @include mediaBigDesktop {
      gap: big(5);
    }

    @include mediaMobile {
      display: none;
    }
  }
}
