.text-input {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: rem(5);

  @include mediaBigDesktop
   {
    gap: big(5);
   }

  &__subtitle {
    color: var(--text-dark-primary);
  }

  &__input {
    padding: rem(9) rem(14);

    color: var(--text-dark-primary);
    font-size: rem(16);
    line-height: 1.5;

    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark);
    border-radius: var(--radius-common);

    transition: border-color var(--animation-timing) var(--cubic-bezier),
      background-color var(--animation-timing) var(--cubic-bezier),
      color var(--animation-timing) var(--cubic-bezier);

    outline: none;
    resize: none;

    @include mediaBigDesktop {
      padding: big(9) big(14);

      font-size: big(16);

      border-width: big(1);
    }

    @include mediaLaptop {
      padding: rem(10.5) rem(14);

      font-size: rem(14);
    }

    @include hover {
      border-color: var(--accent-color);
    }

    &:focus {
      border-color: var(--accent-color);
    }

    &:disabled {
      color: var(--text-disable);

      background-color: var(--bg-disable);
      border-color: var(--stroke-dark);

      &::placeholder {
        color: var(--text-disable);
      }
    }

    &::placeholder {
      color: var(--text-dark-tertiary);
    }
  }
}

.common-input {
  &__icon {
    width: rem(20);
    height: rem(20);
    flex-shrink: 0;
    fill: var(--system-states-error);

    user-select: none;

    @include mediaBigDesktop {
      width: big(20);
      height: big(20);
    }
  }

  &__error {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    margin-top: rem(-10);
    padding: rem(10);

    width: 100%;
    display: flex;
    flex-direction: row;
    gap: rem(10);
    align-items: start;
    justify-content: start;

    background-color: var(--bg-white);
    box-shadow: 0px 4px 8px 4px rgba(142, 141, 208, 0.16);

    transition: margin-top var(--animation-timing) var(--cubic-bezier),
      opacity var(--animation-timing) var(--cubic-bezier),
      visibility var(--animation-timing) var(--cubic-bezier);

    pointer-events: none;
    opacity: 1;
    visibility: hidden;

    @include mediaBigDesktop {
      margin-top: big(-10);
      padding: big(10);
      gap: big(10);

      box-shadow: 0px big(4) big(8) big(4) rgba(142, 141, 208, 0.16);
    }
  }

  &_error {
    & .common-input {
      &__error {
        margin-top: rem(10);

        opacity: 1 !important;
        visibility: visible;

        pointer-events: all;

        @include mediaBigDesktop {
          margin-top: big(10);
        }
        
        &>.common-input__text.text {
          color: var(--text-dark-primary);
        }
      }
    }
    &>.text-input__input {
      border-color: var(--system-states-error);
    }
  }
}
