.breadcrumbs {
  &__list {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: rem(10);

    list-style: none;

    @include mediaBigDesktop {
      gap: big(10);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    &:last-child {
      &::after {
        display: none;
      }

      & .breadcrumbs {
        &__link {
          color: var(--text-dark-primary);

          pointer-events: none;
        }
      }
    }

    &::after {
      content: "";

      width: rem(2);
      height: rem(2);
      display: flex;
      flex-shrink: 0;

      background-color: var(--text-dark-secondary);

      user-select: none;
    }
  }

  &__link {
    position: relative;

    color: var(--text-dark-secondary);
    text-decoration: none;

    cursor: pointer;

    transition: color var(--animation-timing) var(--cubic-bezier);

    @include hover {
      color: var(--accent-color);
    }

    &::before {
      @include pseudo(8) {}
    }
  }
}
