.button {
  position: relative;
  padding: 0;
  margin: 0;

  width: fit-content;

  font-size: rem(16);
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;

  background: transparent;
  border: none;
  border-radius: var(--radius-common);

  cursor: pointer;
  user-select: none;
  overflow: hidden;

  @include mediaBigDesktop {
    font-size: big(16);
  }

  @include mediaLaptop {
    font-size: rem(14);
  }

  &:disabled,
  &.disabled {
    color: var(--text-disable);

    background-color: var(--bg-disable);

    pointer-events: none;

    & .button__icon {
      fill: var(--text-disable);
    }
  }

  &_primary {
    padding: rem(10) rem(20);

    min-height: rem(44);
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--text-light-primary);

    background-color: var(--accent-color);

    @include mediaBigDesktop {
      padding: big(10) big(20);

      min-height: big(44);
    }

    @include hover {
      &::before {
        left: 0;
        right: unset;

        width: 100%;
      }
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      right: 0;
      top: 0;

      width: 0%;
      height: 100%;

      background-color: var(--accent-color-dark);
      border-radius: var(--radius-common);

      transition: width var(--animation-timing-medium) var(--cubic-bezier);
    }
  }

  &_secondary {
    padding: rem(8) rem(18);

    min-height: rem(44);
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--accent-color);

    background: transparent;
    border: solid 1px var(--accent-color);

    transition: background-color var(--animation-timing) var(--cubic-bezier),
      color var(--animation-timing) var(--cubic-bezier);

      @include mediaBigDesktop {
        padding: big(8) big(18);

        min-height: big(44);

        border-width: big(1);
      }

    @include hover {
      color: var(--text-light-primary);

      // background-color: var(--accent-color);

      &::before {
        left: 0;
        right: unset;

        width: 100%;
      }

      & .button__icon {
        fill: var(--bg-white);
      }
    }

    & .button {
      &__icon {
        fill: var(--accent-color);
      }
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      right: 0;
      top: 0;

      width: 0%;
      height: 100%;

      background-color: var(--accent-color);

      transition: width var(--animation-timing-medium) var(--cubic-bezier);
    }

    &:disabled,
    &.disabled {
      background-color: transparent;
      border: solid 1px var(--stroke-dark);

      @include mediaBigDesktop {
        border-width: big(1);
      }
    }
  }

  &_tertiary {
    position: relative;

    display: flex;
    gap: rem(5);

    color: var(--accent-color);

    transition: color var(--animation-timing) var(--cubic-bezier);

    overflow: hidden;

    @include mediaBigDesktop {
      gap: big(5);
    }

    & .button {
      &__content {
        gap: rem(5);

        pointer-events: none;

        @include mediaBigDesktop {
          gap: big(5);
        }
      }

      &__icon {
        position: relative;
        fill: var(--accent-color);
      }
    }

    &.button_icon-right {
      & .button__icon {
        transform: scale(-1, 1);
      }
    }

    @include hover {
      color: var(--accent-color-dark);

      & .button__icon {
        fill: var(--accent-color-dark);
      }
    }

    &:disabled,
    &.disabled {
      color: var(--text-disable);

      background-color: transparent;
    }

    &::before {
      @include pseudo(15) {}
    }
  }
  &_more {
      margin-right: auto;
      margin-left: auto;
  }
  &_icon {
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--elements-dark);

    border: solid 1px var(--stroke-dark);
    background-color: var(--bg-white);

    transition: color var(--animation-timing) var(--cubic-bezier),
    border-color var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      border-width: big(1);
    }

    @include hover {
      color: var(--accent-color);

      & .button {
        &__icon {
          fill: var(--accent-color);
        }

        &__content {
          color: var(--accent-color);
        }
      }
    }
    
    &.animation {
      --before-top: 0;
      --before-right: 0;
      --before-bottom: 0;
      --before-left: 0;
      --before-size: 0;
      --before-scale: 0;

      @include hover {
        color: var(--text-light-primary);

        border-color: var(--accent-color);

        & .button {
          &__icon {
            fill: var(--elements-white);
          }

          &__content {
            color: var(--text-light-primary);
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        z-index: 0;
        top: var(--before-top);
        left: var(--before-left);

        width: var(--before-size);
        height: var(--before-size);
        background-color: var(--accent-color);

        border-radius: rem(1600);
        transform: translate(-50%, -50%);

        @include mediaBigDesktop {
          border-radius: big(1600);
        }
      }
    }
    
    &.msfavorites {
        @include hover {
            & .button__icon {
                fill: transparent;
                stroke: var(--elements-white);

                @include mediaMobile {
                    stroke: var(--accent-color);
                }
            }
        }
        
        & .button__icon {
            fill: transparent;
            stroke: var(--elements-dark);
            stroke-width: 1.5;
            transition: fill var(--animation-timing) var(--cubic-bezier), stroke var(--animation-timing) var(--cubic-bezier);
        }
        &.voted {
            & .button__icon {
                fill: var(--accent-color);
                stroke: var(--accent-color);
            }
            @include hover {
                & .button__icon {
                    fill: var(--elements-white);
                    stroke: var(--elements-white);
                }
            }
        }
    }

    &:disabled,
    &.disabled {
      fill: var(--text-disable);

      background-color: transparent;
    }

    &-right {
      & .button {
        &__content {
          flex-direction: row;
        }
      }
    }

    & .button {
      &__icon {
        position: relative;
        z-index: 1;

        fill: var(--elements-dark);
      }

      &__content {
        pointer-events: none;
      }
    }

    &.large {
      padding: rem(11);

      @include mediaBigDesktop {
        padding: big(11);
      }
    }

    &.medium {
      padding: rem(6);

      @include mediaBigDesktop {
        padding: big(6);
      }
    }

    &.small {
      padding: rem(3);

      @include mediaBigDesktop {
        padding: big(3);
      }

      & .button__icon {
        width: rem(16);
        height: rem(16);

        @include mediaBigDesktop {
          width: big(16);
          height: big(16);
        }
      }
    }
  }

  &_not-wrap {
    white-space: nowrap;
  }

  &__icon {
    width: rem(20);
    height: rem(20);
    order: 2;

    fill: var(--bg-white);

    transition: fill var(--animation-timing) var(--cubic-bezier);

    pointer-events: none;

    @include mediaBigDesktop {
      width: big(20);
      height: big(20);
    }
  }

  &__content {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: rem(8);

    @include mediaBigDesktop {
      gap: big(8);
    }
  }

  &_theme-whatsapp {
    background-color: #29A71A;

    @include hover {
      & .button__icon {
        fill: var(--bg-white);
      }
    }

    .button__icon {
      fill: var(--bg-white);
    }
  }

  &_theme-telegram {
    background-color: #29A9EB;

    @include hover {
      & .button__icon {
        fill: var(--bg-white);
      }
    }

    .button__icon {
      fill: var(--bg-white);
    }
  }
}

.whatsapp-button {
    z-index: 10;
    position: fixed;
    right: rem(30);
    bottom: rem(10);
    padding: rem(10) rem(15);
    background-color: #28a745;
    text-decoration: none;
    z-index: 1000;
    color: #fff;
    box-shadow: rem(4) rem(4) rem(8) 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s bottom;
    
    @include mediaBigDesktop {
        right: big(30);
        bottom: big(10);
        padding: big(10) big(15);
        box-shadow: big(4) big(4) big(8) 0 rgba(0, 0, 0, 0.2);
    }
    
    @include mediaMobile {
        right: rem(10);
        font-size: rem(12);
    }
    
    &:hover {
        color: #fff;
        text-decoration: none;
    }
    
    &__icon {
        width: rem(35);
        height: rem(35);
        display: inline-block;
        vertical-align: middle;
        margin-right: rem(10);
        fill: var(--bg-white);
        
        @include mediaBigDesktop {
            width: big(35);
            height: big(35);
            margin-right: big(10);
        }
        
        @include mediaMobile {
            width: rem(25);
            height: rem(25);
            margin-right: 0;
        }
    }
    
    span {
        @include mediaMobile {
            display: none;
        }
    }

    &_up {
        bottom: rem(80);

        @include mediaBigDesktop {
            bottom: big(80);
        }
    }
}