.section {
  width: 100%;
  position: relative;
  overflow: hidden;

  & .container {
    display: flex;
    flex-direction: column;
    gap: var(--section-gap);
  }

  & .breadcrumbs {
    padding: rem(11) 0;

    @include mediaBigDesktop {
      padding: big(11) 0;
    }
  }

  &_background {
    background-color: var(--section-background-color);
  }

  &_text-light {
    --section-text-color-primary: var(--text-light-primary);
    --section-text-color-secondary: var(--text-light-secondary);
  }

  &_common {
    & .container {
      gap: var(--section-gap-common);
    }

    &:not(.section_padding-top):not(.section_breadcrumbs) {
      padding-top: var(--section-padding-common);
    }
  }

  &_padding-top {
    padding-top: var(--section-padding);
  }

  &_padding-bottom {
    padding-bottom: var(--section-padding);
  }

  &_padding-bottom-small {
    padding-bottom: var(--section-gap-common);
  }

  &__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaTablet {
      gap: rem(50);
    }

    @include mediaMobile {
      gap: rem(15);
    }

    &_column {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      gap: rem(25);

      @include mediaBigDesktop {
        gap: big(25);
      }

      @include mediaLaptop {
        gap: rem(20);
      }

      @include mediaTablet {
        gap: rem(15);
      }
    }
  }

  &__image {
    width: 100%;
    height: rem(500);

    background-color: var(--bg-light);
    border-radius: var(--radius-common);
    overflow: hidden;
    
    @include mediaDesktop {
      height: auto;
      aspect-ratio: 2260/700;
      
      @supports not (aspect-ratio:1/1) {
        height: rem(700);
      }
    }

    @include mediaBigDesktop {
      height: big(500);
    }

    @include mediaLaptop {
      height: rem(400);
    }

    @include mediaTablet {
      height: rem(300);
    }

    @include mediaMobile {
      height: rem(160);
    }

    & img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;

      pointer-events: none;
      user-select: none;
    }
  }

  &__title {
    width: 100%;
    color: var(--section-text-color-primary);
  }

  &__navigation {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }
    
    & .button__content {
      white-space: nowrap;
    }
  }

  &__swiper-navigation {
    display: flex;
    flex-direction: row;
    gap: rem(5);

    @include mediaBigDesktop {
      gap: big(5);
    }

    @include mediaMobile {
      display: none;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--section-wrapper-gap);

    &_row {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    & .pagination {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
