.price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: rem(10);

  @include mediaBigDesktop {
    gap: big(10);
  }

  &__old {
    color: var(--text-dark-secondary);

    @include mediaMobile {
      font-size: rem(12);
    }
  }

  &__sum {
    color: var(--text-dark-primary);

    @include mediaMobile {
      font-size: rem(16);
    }
  }
}
