.warning {
    display: block;
    width: rem(280);
    padding: rem(14) rem(10);
    background-color: white;
    box-shadow: 0 0 rem(20) rgba(0,0,0,0.1);
    position: fixed;
    bottom: rem(30);
    left: rem(30);
    z-index: 200;
    color: #635f59;
    font-size: rem(12);
    transition: bottom var(--animation-timing-medium);
    
    @include mediaBigDesktop {
        width: big(280);
        padding: big(14) big(10);
        box-shadow: 0 0 big(20) rgba(0,0,0,0.1);
        bottom: big(30);
        left: big(30);
        font-size: big(12);
    }
    
    &_close {
        position: absolute;
        display: block;
        right: rem(10);
        top: rem(10);
        width: rem(12);
        height: rem(10);
        border-radius: 100%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #017ABB;
        
        @include mediaBigDesktop {
            right: big(10);
            top: big(10);
            width: big(12);
            height: big(10);
        }
        
        &:before, &:after {
            content: '';
            display: block;
            width: rem(10);
            height: rem(1);
            background-color:  var(--accent-color);
            position: absolute;
            top: 50%;
            left: 50%;
            
            @include mediaBigDesktop {
                width: big(10);
                height: big(1);
            }
        }
        
        &:before{
            transform: translate(-50%, -50%) rotate(45deg);
        }
        
        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
    
    &_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: rem(15);
        
        @include mediaBigDesktop {
            gap: big(15);
        }
        
        a {
            color:  var(--accent-color);
        }
    }
    
    button {
        border: none;
        outline: none;
        display: block;
        background-color: var(--accent-color);
        color: #ffffff;
        padding: rem(11) rem(12) rem(9) rem(12);
        font-size: rem(12);
        border-radius: rem(20);
        font-weight: bold;
        transition: all ease 0.3s;
        cursor: pointer;
    }
    
    button:hover {
        color: #ffffff;
        background-color: var(--accent-color-dark);
    }

    &.cookie_scroll_up {
        bottom: rem(80);

        @include mediaBigDesktop {
            bottom: big(80);
        }
    }
}
