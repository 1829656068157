@import '../../mixins';

.fancybox {
  &__container {
    --fancybox-bg: rgba(0, 0, 0, 0.60);

    & .fancybox {
      &__slide {
        padding: rem(50) rem(20);

        @include mediaBigDesktop {
          padding: big(50) rem(20);
        }

        @include mediaMobile {
          padding: 0 !important;
        }

        &.has-image {
          @include media(#{rem(1000)}) {
            padding: rem(20) !important;
          }

          @include mediaMobile {
            padding: rem(15) !important;
          }

          & .fancybox {
            &__content {
              max-width: rem(1074);

              @include mediaBigDesktop {
                max-width: big(1174);
              }

              @include mediaLaptop {
                max-width: rem(820);
              }
            }
          }

          & img {
            border-radius: var(--radius-common);

            user-select: none;
          }
        }
      }

      &__nav {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        padding: 0 var(--container-offset);

        width: 100%;
        max-width: calc(var(--container-width));
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        pointer-events: none;
      }
    }

    & .f-button {

      &.is-next,
      &.is-prev {
        position: relative !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        margin: auto !important;

        width: rem(44);
        height: rem(44);
        display: flex;
        align-items: center;
        justify-content: center;

        color: var(--elements-dark);

        border-radius: var(--radius-common);
        border: solid 1px var(--stroke-dark);
        background-color: var(--bg-white);

        transition: color var(--animation-timing) var(--cubic-bezier);

        pointer-events: all;
        transform: translateY(0) !important;

        @include mediaBigDesktop {
          width: big(44);
          height: big(44);

          border-width: big(1);
        }

        @include media(#{rem(1000)}) {
          width: rem(34);
          height: rem(34);
        }

        @include mediaMobile {
          width: rem(24);
          height: rem(24);
        }

        @include hover {
          color: var(--accent-color);

          &::after {
            background-color: var(--accent-color);
          }
        }

        &:disabled {
          fill: var(--text-disable);

          background-color: transparent;
        }

        & svg {
          display: none;
        }

        &::before {
          @include pseudo(6)
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;

          width: rem(20);
          height: rem(20);

          background-color: var(--elements-dark);
          mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M9.11719 10.0924L13.2422 14.2174L12.0639 15.3957L6.76052 10.0924L12.0639 4.78906L13.2422 5.9674L9.11719 10.0924Z' fill='%23222222'/%3E%3C/svg%3E");
          mask-size: contain;
          mask-position: center;
          mask-repeat: no-repeat;

          transition: background-color var(--animation-timing) var(--cubic-bezier);

          @include mediaBigDesktop {
            width: big(20);
            height: big(20);
          }
        }
      }

      &.is-next {
        margin-right: 0 !important;

        transform: scale(-1, 1) !important;

        @include media(#{rem(1000)}) {
          margin-right: rem(20) !important;
        }

        @include mediaMobile {
          margin-right: rem(10) !important;
        }
      }

      &.is-prev {
        margin-left: 0 !important;

        @include media(#{rem(1000)}) {
          margin-left: rem(20) !important;
        }

        @include mediaMobile {
          margin-left: rem(10) !important;
        }
      }
    }

    &.has-toolbar {
      --fancybox-bg: rgba(0, 0, 0, 0.85);

      & .fancybox {
        &__toolbar {
          top: rem(50);

          @include mediaBigDesktop {
            top: big(50);
          }

          @include mediaLaptop {
            top: rem(40);
          }

          @include media(#{rem(1000)}) {
            top: rem(20);
          }

          & .is-right {
            margin-right: rem(50);

            @include mediaBigDesktop {
              margin-right: big(50);
            }

            @include mediaLaptop {
              margin-right: rem(40);
            }

            @include media(#{rem(1000)}) {
              margin-right: rem(20);
            }

            & .f-button {
              width: rem(20);
              height: rem(20);

              @include mediaBigDesktop {
                width: big(20);
                height: big(20);
              }

              &[data-fancybox-close] {
                background: transparent;

                @include hover {
                  &::after {
                    background-color: var(--accent-color);
                  }
                }

                & svg {
                  display: none;
                }

                &::before {
                  @include pseudo(10)
                }

                &::after {
                  content: "";
                  width: 100%;
                  height: 100%;

                  background-color: var(--elements-white);

                  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.4144 10.0002L16.4144 5.00015L15.0002 3.58594L10.0002 8.58594L5.00015 3.58594L3.58594 5.00015L8.58594 10.0002L3.58594 15.0002L5.00015 16.4144L10.0002 11.4144L15.0002 16.4144L16.4144 15.0002L11.4144 10.0002Z' fill='white'/%3E%3C/svg%3E");
                  mask-size: contain;
                  mask-position: center;
                  mask-repeat: no-repeat;

                  transition: background-color var(--animation-timing) var(--cubic-bezier);
                }
              }
            }
          }
        }
      }
    }
  }

  &__content {
    & .f-button {
      &.is-close-btn {
        top: rem(20) !important;
        right: rem(20) !important;

        width: rem(20);
        height: rem(20);

        background: none;

        opacity: 1;

        @include mediaBigDesktop {
          top: big(20) !important;
          right: big(20) !important;

          width: big(20);
          height: big(20);
        }

        @include mediaMobile {
          top: rem(15) !important;
          right: rem(15) !important;
        }

        @include hover {

          &::before,
          &::after {
            background-color: var(--accent-color);

            transform: rotate(0deg);
          }
        }

        & svg {
          display: none;
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;

          width: rem(16);
          height: rem(2);

          background-color: var(--elements-dark);

          transition: background-color var(--animation-timing) var(--cubic-bezier),
            transform var(--animation-timing) var(--cubic-bezier);

          @include mediaBigDesktop {
            width: big(16);
            height: big(2);
          }
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
