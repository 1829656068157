ul.list,
ol.list,
.article ul,
.article ol {
  margin: 0;
  padding: 0;
  list-style: none;

  color: var(--section-text-color-secondary);
  font-size: rem(18);
  font-weight: 400;
  line-height: 1.5;

  @include mediaBigDesktop {
    font-size: big(18);
  }

  @include mediaLaptop {
    font-size: rem(16);
  }

  @include mediaMobile {
    font-size: rem(14);
  }

  & li {
    padding-left: rem(15);

    display: block;

    @include mediaBigDesktop {
      padding-left: big(15);
    }

    @include mediaLaptop {
      padding-left: rem(10);
    }

    &::before {
      margin-right: rem(15);

      @include mediaLaptop {
        margin-right: rem(10);
      }
    }

    & b,
    & strong {
      color: var(--section-text-color-primary);
      font-weight: 600;
    }
  }
}

ul.list,
.article ul {
  & li {
    padding-left: rem(35);

    text-indent: rem(-20);

    @include mediaBigDesktop {
      padding-left: big(35);

      text-indent: big(-15);
    }

    @include mediaLaptop {
      padding-left: rem(25);

      text-indent: rem(-15);
    }

    &::before {
      content: "";
      position: relative;
      bottom: rem(4);

      width: rem(5);
      height: rem(5);
      display: inline-block;

      background-color: var(--accent-color);
      border-radius: rem(999);

      @include mediaBigDesktop {
        bottom: big(4);

        width: big(5);
        height: big(5);

        border-radius: big(999);
      }

      @include mediaLaptop {
        bottom: rem(3);
      }

      @include mediaMobile {
        bottom: rem(2);
      }
    }
  }
}

ol.list,
.article ol {
  list-style-type: none;
  counter-reset: num;

  & li {
    padding-left: rem(56);

    text-indent: rem(-20);

    @include mediaBigDesktop {
      padding-left: big(56);

      text-indent: big(-15);
    }

    @include mediaLaptop {
      padding-left: rem(43);

      text-indent: rem(-16);
    }

    @include mediaMobile {
      padding-left: rem(40);

      text-indent: rem(-15);
    }

    &::before {
      display: inline-block;

      color: var(--accent-color);

      content: '0' counter(num) '.';
      counter-increment: num;
    }
  }
}
