@import '../../mixins';

.article {

  &_full-width {
    max-width: unset;
  }

  margin-left: auto;
  margin-right: auto;

  width: 100%;
  max-width: var(--article-width);
  display: flex;
  flex-direction: column;
  gap: rem(25);

  @include mediaMobile {
    gap: rem(15);
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6,
  & p,
  & span:not(.tag),
  & a:not(.tag) {
    margin: 0;
    padding: 0;
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: var(--section-text-color-primary);
  }
  
  & em {
    font-style: normal;    
  }
  
  & p,
  & span:not(.tag):not(.button__content):not(.button__text) {
    color: var(--section-text-color-secondary);
    font-size: rem(18);
    line-height: 1.5;

    @include mediaBigDesktop {
      font-size: big(18);
    }

    @include mediaLaptop {
      font-size: rem(16);
    }

    @include mediaMobile {
      font-size: rem(14);
    }
  }

  & ul,
  & ol {
    display: flex;
    flex-direction: column;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }
  }

  &__date {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: rem(10);

    color: var(--section-text-color-secondary);

    @include mediaBigDesktop {
      gap: big(10);
    }
  }

  &__calendar {
    width: rem(16);
    height: rem(16);

    fill: var(--section-text-color-secondary);

    @include mediaBigDesktop {
      width: big(16);
      height: big(16);
    }
  }

  &__tag-list {
    margin-bottom: rem(5);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: rem(20);

    @include mediaBigDesktop {
      margin-bottom: big(5);
      
      gap: big(20);
    }

    @include mediaLaptop {
      margin-bottom: rem(-5);
    }

    @include mediaMobile {
      margin-bottom: 0;

      gap: rem(15);
    }
  }
}
